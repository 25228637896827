import React from 'react';
import {
    Box,
    CircularProgress,
    Typography
} from '@mui/material';

const LoadingReport = () => {
    return (
        <Box sx={{ height: '500px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <CircularProgress size={100} color="warning"/>
            <Typography p={5} variant="h5">
                Generating Report this may take a few minutes
            </Typography>
        </Box>
    )
};

export default LoadingReport;