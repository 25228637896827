import React from 'react';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../components/Page';
import {
    Box,
    Typography,
    Button,
    Hidden,
    Modal,
    Chip,    
} from '@mui/material';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarExport
} from '@mui/x-data-grid';
import {
    Wifi,
    WifiOff,
} from 'react-feather';
import NotesModal from './LicensingInfoModal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const LicenseDataGrid = () => {
    /// Company data grid for super admins shows all registered companies
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [check, setCheck] = React.useState(false);
    const [rowItem, setRowItem] = useState();
    const [editRowsModel, setEditRowsModel] = React.useState({});
    const [open1, setOpen1] = React.useState(false);
    const handleOpen1 = () => setOpen1(true);
    const handleClose1 = () => setOpen1(false);
    const [open2, setOpen2] = React.useState(false);
    const handleOpen2 = () => setOpen2(true);
    const handleClose2 = () => setOpen2(false);

    const licensesData = useSelector((state) => state.LicenseReducer.allLicenses);
    // console.log("LICENSE", licensesData)

    function showInfo(cellValues) { // hadles the double click to bring up modal to edit crop amount
        setRowItem({
            id: cellValues.row.id,
            licenseNumber: cellValues.row.licenseNumber,
            used: cellValues.row.used,
            userId: cellValues.row.userId,
            licenseUserName: `${cellValues.row.userFN} ${cellValues.row.userLN}`,
            licenseDayUsed: cellValues.row.licenseDayUsed, 
            expirationLength: cellValues.row.expirationLength, 
            licenseCreated: cellValues.row.licenseCreated,
            licenseUpdated: cellValues.row.licenseUpdated,
            licenseExpiration: cellValues.row.licenseExpiration,
            licenseActivation: cellValues.row.licenseActivation,
            notes: cellValues.row.notes,
        })
        handleOpen1();
    };

    const licenseReadableData = [];
    licensesData?.forEach(licensePusher);
    function licensePusher(licensesData) {
        licenseReadableData.push({
            id: licensesData.id,
            licenseNumber: licensesData.licenseNumber,
            used: licensesData.used,
            userId: licensesData.userId,
            licenseUserName: `${licensesData.userFN} ${licensesData.userLN}`,
            licenseDayUsed: licensesData.licenseDayUsed, 
            expirationLength: licensesData.expirationLength, 
            licenseCreated: licensesData.licenseCreated,
            licenseUpdated: licensesData.licenseUpdated,
            licenseExpiration: licensesData.licenseExpiration,
            licenseActivation: licensesData.licenseActivation,
            notes: licensesData.notes,
        });
    };
    // console.log("License data", licenseReadableData);

    const columns = [
        { field: 'licenseNumber', headerName: 'License #', flex: .75, headerClassName: 'header' },
        { field: 'used', headerName: 'Status', flex: .3, headerClassName: 'header',
            renderCell: (cellValues)=>{
                if (cellValues.row.used === true && cellValues.row.licenseDayUsed <= 0 && cellValues.row.licenseDayUsed != null){
                    return(
                        <Chip icon={<WifiOff/>} variant="contained" color="warning" label="Expired"/>
                    )
                }
                else if (cellValues.row.used === true && cellValues.row.licenseDayUsed > 0){
                    return(
                        <Chip icon={<Wifi/>} variant="contained" color="success" label="Activated"/>
                    )
                } 
                else if (cellValues.row.used === true && cellValues.row.licenseDayUsed == null && cellValues.row.expirationLength == null){
                    return(
                        <Chip icon={<Wifi/>} variant="contained" color="success" label="Activated"/>
                    )
                } 
                else if(cellValues.row.used === false){
                    return(
                        <Chip icon={<WifiOff/>} variant="contained" color="secondary" label="Not Activated"/>
                    )
                }
            } 
        },
        { field: 'licenseUserName', headerName: 'User', flex: .5, headerClassName: 'header',
            renderCell: (cellValues) => {
                if(cellValues.row.licenseUserName == `null null`){
                    return(
                        <p>N/A</p>
                    )
                } else {
                    return(
                        <p>{cellValues.row.licenseUserName}</p>
                    )
                }
            }
        },
        { field: 'licenseCreated', headerName: 'Generated Date', flex: .3, headerClassName: 'header',
            renderCell: (params) => {
            let date = new Date(params.row.licenseCreated);
            const options = {
                year: "numeric",
                month: "numeric",
                day: "numeric",
              };
            return date.toLocaleString("en-US",options)
        }},
        { field: 'licenseActivation', headerName: 'Activated Date', flex: .3, headerClassName: 'header', renderCell: (params) => {
            if (params.row.used != true){
                return (<string>N/A</string>)
            } else {
                let date = new Date(params.row.licenseActivation);
                const options = {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                };
                return date.toLocaleString("en-US",options)            }
        }},
        { field: 'licenseExpiration', headerName: 'Expiration Date', flex: .3, headerClassName: 'header',
            renderCell: (params) => {
                if(params.row.licenseDayUsed == null && params.row.expirationLength == null){
                    return(<string>Perpetual</string>)
                }else if(params.row.used == false){
                    return(<string>N/A</string>)
                }else{
                    let date = new Date(params.row.licenseExpiration);
                    const options = {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                      };
                    return date.toLocaleString("en-US",options)
                }
        }},
        { field: 'licenseDayUsed', headerName: 'Duration', flex: .3, headerClassName: 'header',
            renderCell: (cellValues) => {
                if(cellValues.row.licenseDayUsed > 0 && cellValues.row.used === true){
                    return(
                        <p>{cellValues.row.licenseDayUsed} / {cellValues.row.expirationLength}</p>
                    )
                } else if (cellValues.row.used === false && cellValues.row.expirationLength != null){
                    return(
                        <p>Days: {cellValues.row.expirationLength}</p>
                    )
                } else if (cellValues.row.licenseDayUsed <= 0 && cellValues.row.licenseDayUsed != null){
                    return(
                        <p>Expired</p>
                    )
                } else if (cellValues.row.licenseDayUsed == null && cellValues.row.expirationLength == null) {
                    return(
                        <p>Perpetual</p>
                    )
                }
            }
        },
        { field: 'notes', headerName: 'Notes', flex: .5, headerClassName: 'header',
            renderCell: (cellValues) => {
                if(cellValues.row.notes != null){
                    return(<Button onClick={() => showInfo(cellValues)} style={{justifyContent:'flex-start', overflow: 'hidden', textOverflow: 'ellipsis', }}>{cellValues.row.notes}</Button>)
                } else {
                    return(<Button onClick={() => showInfo(cellValues)} style={{justifyContent:'flex-start', overflow: 'hidden', textOverflow: 'ellipsis'}}>No Notes</Button>)
                }
            }
        }
    ];

    const rows = licenseReadableData;

    function CustomToolBar() {
        return (
            <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #e0e0e0', p: 1 }}>
                <Hidden smDown>
                    <Box width='40%' textAlign="left">
                        <GridToolbarFilterButton/>
                        <GridToolbarColumnsButton/>
                        <GridToolbarDensitySelector/>
                        <GridToolbarExport/>
                    </Box>
                </Hidden>
                <Hidden smUp>
                </Hidden>
            </GridToolbarContainer>
        )
    };

    useEffect(() => {
        // dispatch({ type: 'GET_LICENSES' });
    }, [licensesData]);

    return (
        <Page title="Companies" sx={{height: "100%"}}>
            <Box p={3} sx={{ '& .header': { backgroundColor: '#e2e2e2' }, height: "100%" }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant='h3' textAlign='left' p={2}>
                        Licenses
                    </Typography>
                </Box>
                <DataGrid
                    columns={columns}
                    rows={rows}
                    sx={{ height: '70vh' }}
                    components={{
                        Toolbar: CustomToolBar
                    }}
                />
                <Modal
                    open={open1}
                    onClose={handleClose1}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <NotesModal item={rowItem} style={style} handleClose1={handleClose1} handleClose2={handleClose2}/>
                </Modal>
            </Box>
        </Page>
    )
};

export default LicenseDataGrid;