import React from 'react';

import {
    Box,
    Typography,
} from "@mui/material";

function MessageDisplay({item, style}){
  /// displays message from customer for processor
    return(
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Message from {item.userName}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 4 }}>
            { item.message === '' ?
                'N/A'
            :
                `${item.message}`
            }
          </Typography>
        </Box>
    )
}

export default MessageDisplay;