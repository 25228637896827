import React from "react";
import {
    Box,
    Button,
    Typography
} from '@mui/material';
import { useDispatch } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";

const ConfirmEmail = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleConfirm = () => {
        const urlString = window.location.href;
        const url = new URL(urlString);
        const confirmToken = url.searchParams.get("confirmToken");
        dispatch({type: "CONFIRM_EMAIL", payload: confirmToken, navigate})
    }

    return (
        <Box>
            <Typography
                variant="h3"
                p={2}
            >
                Welcome to Botweb
            </Typography>
            <Typography
                variant="body1"
                p={2}
            >
                Confirm your account by clicking button below
            </Typography>
            <Button 
                color="primary"
                variant="contained"
                onClick={handleConfirm}
            >
                Confirm
            </Button>
        </Box>
    )
};

export default ConfirmEmail;