import React from 'react';
import {
    Box,
    Button,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
    TextField,
} from '@mui/material';
import { Folder } from 'react-feather';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from "react-redux";
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';


const Upload = React.forwardRef((props, ref) => {
    /// File upload for plans to be processed
    const dispatch = useDispatch();
    const customerSelect = useSelector((state) => state.UserReducer.customerSelection);
    const userdata = useSelector((state)=> state.UserReducer.user);
    const company = useSelector((state) => state.CompanyReducer.userCompanies[0]);
    const filePoints = useSelector((state) => state.FileReducer.filePoints);
    const [selectFilePoints, setSelectFilePoints] = React.useState([]);
    const [clicked, setClicked] = React.useState(false);
    console.log("FILE POINTS", filePoints);
    console.log("FILE POINTS", filePoints.length);
    console.log("FILE POINTS", filePoints?.pointList);
    console.log("Select File Points", selectFilePoints);

    const [pdfStatus, setPdfStatus] = React.useState();
    const { close,
        // pointPlans,
        // setPointSelection,
        // selectFilePoints, 
        // setSelectFilePoints, 
        // filePoints, 
        setClientName, setJobName, setAreaName, setFloorName, setPlanName, setFile, handleUpload, fileName, clientName, jobName, areaName, floorName, planName, setFileName, pdfUrl } = props;
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
    if ((clientName != '')||(jobName != '')||(areaName != '')||(floorName != '')||(planName != '')){
        setFileName(`${clientName}${jobName}${areaName}${floorName}${planName}BM`)
    };

    let user = {
        Id: userdata.id,
        hasAcceptedUploadPolicy: userdata.hasAcceptedUploadPolicy,
        hasAcceptedDownloadPolicy: userdata.hasAcceptedDownloadPolicy,
        hasConfirmedEmail: userdata.hasConfirmedEmail,
        hasReadPrivacyPolicy: userdata.hasReadPrivacyPolicy,
    };

    React.useEffect(() => {
        dispatch({ type: 'GET_PDF_FILE', payload: 1 });
        setFile(acceptedFiles);
        if (userdata.hasAcceptedUploadPolicy === 0){
            setPdfStatus(false)
        }
        if (userdata.hasAcceptedUploadPolicy === 1){
            setPdfStatus(true)
        }
        setSelectFilePoints(filePoints.pointList);
    },[acceptedFiles, filePoints],);

    const files = acceptedFiles.map(file => (
        <Tooltip key={file.path} title="Click to select different file">
            <ListItem sx={{ border: '1px solid black' }}>
                    <ListItemIcon>
                        <Folder/>
                    </ListItemIcon>
                    <ListItemText>
                        {file.path} - {file.size} bytes
                    </ListItemText>
            </ListItem>
        </Tooltip>
    ));

    /// Update user information once user has accepted download agreement
    function changePDFStatus(event){
        setPdfStatus(!pdfStatus);
        if (event === true) {
            user = {
                Id: userdata.id,
                hasAcceptedUploadPolicy: 1,
                hasAcceptedDownloadPolicy: userdata.hasAcceptedDownloadPolicy,
                hasConfirmedEmail: userdata.hasConfirmedEmail,
                hasReadPrivacyPolicy: userdata.hasReadPrivacyPolicy,
            }
            console.log(user)
            // if ( pdfStatus === false){
                dispatch({ type: "UPDATE_AGREEMENT", payload: user});
            // }
        }
        else if (event === false) {
            user = {
                Id: userdata.id,
                hasAcceptedUploadPolicy: 0,
                hasAcceptedDownloadPolicy: userdata.hasAcceptedDownloadPolicy,
                hasConfirmedEmail: userdata.hasConfirmedEmail,
                hasReadPrivacyPolicy: userdata.hasReadPrivacyPolicy,
            }
            console.log(user)
            // if ( pdfStatus === true){
                dispatch({ type: "UPDATE_AGREEMENT", payload: user});
            // }        
        }
    };

    function setPointSelection(item){
        for (let p = 0; p < filePoints?.pointList.length; p++){
            if (item.description == filePoints?.pointList[p].description){
                filePoints.pointList[p].enabled = !(filePoints.pointList[p].enabled);
                setSelectFilePoints([...selectFilePoints]);
            }
        };
    };

    let fileToSend = {
        companyId: filePoints.companyId,
        ext: filePoints.ext,
        filePath1: filePoints.filePath1,
        id: filePoints.id,
        name: filePoints.name,
        newDwgName: filePoints.newDwgName,
        newTxtName: filePoints.newTxtName,
        pointList: selectFilePoints,
        ziptmp: filePoints.ziptmp,
        customerId: customerSelect
    }

    
    // filePoints.pointList.forEach(item => {
    //     item.typeEnum
    // });

    function finishFileUpload(){
        if (selectFilePoints.length == 0){
            swal({
                title: `No Point Count Found.`,
                position: 'center'
            });
        } else {
            dispatch({ type: "POINT_COUNT_POST", payload: fileToSend});
        }
    };

    return (
        <Box p={3} sx={{ width: '100%', height: '100%', margin: '5%' }}>
            { userdata.hasAcceptedUploadPolicy === 0 ?
            <div style={{marginBottom: 5+'%'}}>
                <h3>Uploader Agreement</h3>
                <p>Read and accept the below User Agreement to upload plans.</p>
                <iframe style={{width: "100%", height: '90vh'}} src={pdfUrl}></iframe>
                <Form style={{margin: '5%'}}>
                    <Label check>
                        <Input type="switch" role="switch" checked={pdfStatus} onChange={(event) => changePDFStatus(event.target.checked)}/>{' '}
                        Accept Upload Policy
                    </Label>
                </Form>
            </div>
            :
            <div>
                { filePoints.length == 0 ?
                <div>
                    <h2>Upload File</h2>
                    <label>Enter a custom <b>File Name</b>. Leave blank for <b>Default</b> file name generation.</label>
                    <br/>
                    <Box style={{margin:10}}>
                        <TextField 
                            style={{margin:5, width: '15%'}}
                            variant='outlined'
                            label='Client'
                            sx={{ width: '20%' }}
                            disabled={company === undefined}
                            onChange={(event) => setClientName(event.target.value+"_")}  
                        />
                        <TextField 
                            style={{margin:5, width: '15%'}}
                            variant='outlined'
                            label='Job'
                            sx={{ width: '20%' }}
                            disabled={company === undefined}
                            onChange={(event) => setJobName(event.target.value+"_")}  
                        />
                        <TextField 
                            style={{margin:5, width: '15%'}}
                            variant='outlined'
                            label='Area'
                            sx={{ width: '20%' }}
                            disabled={company === undefined}
                            onChange={(event) => setAreaName(event.target.value+"_")}  
                        />
                        <TextField 
                            style={{margin:5, width: '15%'}}
                            variant='outlined'
                            label='Floor'
                            sx={{ width: '20%' }}
                            disabled={company === undefined}
                            onChange={(event) => setFloorName(event.target.value+"_")}  
                        />
                        <TextField 
                            style={{margin:5, width: '15%'}}
                            variant='outlined'
                            label='Plan'
                            sx={{ width: '20%' }}
                            disabled={company === undefined}
                            onChange={(event) => setPlanName(event.target.value+"_")}  
                        />
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        flexDirection: 'column'
                    }}>
                        <Box 
                            sx={{ 
                                margin: 5,
                                width: '80%',
                                height: '70%',
                                display: 'flex',
                                justifyContent: 'center', 
                                alignItems: 'center',
                                borderWidth: '2px',
                                borderRadius: '2px',
                                borderStyle: 'dashed',
                                padding: '20px',
                                transition: 'border .24s ease-in-out'
                            }}
                            {...getRootProps({className: 'dropzone'})}
                        >
                            <input disabled={company === undefined} {...getInputProps()}/>
                            { acceptedFiles.length > 0 ? <List>{files}</List> : <p>Drag 'n' drop .txt or .zip (with .txt + .dwg) file here, or click to select a file</p> }
                        </Box>
                    </Box>
                    <Button sx={{ width: '50%' }} disabled={company === undefined} variant="contained" onClick={handleUpload} >Upload</Button>
                </div>
                :
                <div>
                    <h3>Points</h3>
                    <p>Point Count List: All detected points</p>
                    <p>Selected special points will be included in plan processing. Deselected points will be excluded.</p>
                    <Form>
                    {filePoints.pointList.map((item, i) => (
                        <div>
                        { item.typeEnum == 4 ?
                        <div style={{margin:5}}>
                            <Label check>
                                <b>{item.description}</b> #: {item.quantity}
                                <Input type="switch" role="switch" checked={item?.enabled}
                                    value={item?.description}
                                    onChange={() => setPointSelection(item)}
                                />
                            </Label>
                        </div>
                        :
                        <div style={{margin:5}}>
                            <Label check>
                                <b>{item.description}</b> #: {item.quantity}
                            </Label>
                        </div>
                        }
                        </div>
                    ))}
                        <Button onClick={()=> finishFileUpload()}>Submit Point Selection</Button>
                    </Form>
                </div>
                }
            </div>
            }
        </Box>
    )
});

export default Upload;