import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    Typography,
    FormControl,
    Button,
    TextField
} from "@mui/material";
import { Form, FormGroup, Label, Input, FormText, Dropdown } from 'reactstrap';

function NotesModal({item, style}){
  const dispatch = useDispatch();
  /// displays message from customer for processor
  console.error(item)
  const [infoNotes, setInfoNotes] = React.useState(item.notes);

  let lincenseInfo = {
    id: item.id,
    notes: infoNotes
  }

  function submitNotes(){
    dispatch({ type: "UPDATE_LICENSE", payload: lincenseInfo });

    setInfoNotes();
  };

  React.useEffect(() => {

  },[]);

    return(
        <Box sx={style} style={{display: 'flex', justifyContent: 'space-between', justifyContent:'center' }}>
          <Box style={{flex:1, width:'100%'}}>
            <Typography id="modal-modal-title" variant="h5" component="h2" style={{textAlign:'center'}}>
              License Notes
            </Typography>
              <div style={{display:'flex', flexDirection:'column', width:'100%',}}>
                <FormControl sx={{ m: 2 }} style={{width:'100%'}}>
                      <TextField multiline rows={10} value={infoNotes} onChange={(event)=>setInfoNotes(event.target.value)}></TextField>
                </FormControl>
                  <Button onClick={() => submitNotes()}>Update Notes</Button>
              </div>
          </Box>
        </Box>
    )
}

export default NotesModal;