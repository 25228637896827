import React from "react";
import {
    Container,
    Divider,
    Typography
 } from "@mui/material";
 
const LicenseAgreement = () => {
    return (
        <Container maxWidth="sm">
            <Typography variant="h4" align="center">
                Software Licensing Agreement
            </Typography>
            <br/>
            <Divider></Divider>
            <br/>
            <Typography variant="h6" align="left">License subheader</Typography>
            <br/>
            <Typography variant="body1" align="left">
                License will be here. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Netus et malesuada fames ac turpis egestas sed tempus urna. Non diam phasellus vestibulum lorem sed risus ultricies. Suspendisse ultrices gravida dictum fusce ut placerat orci nulla. Gravida arcu ac tortor dignissim convallis aenean. Nibh tellus molestie nunc non. Nulla facilisi etiam dignissim diam quis. Duis convallis convallis tellus id interdum velit laoreet id. Amet aliquam id diam maecenas. Integer quis auctor elit sed vulputate. Enim nec dui nunc mattis.

Feugiat vivamus at augue eget. Ullamcorper morbi tincidunt ornare massa eget egestas purus viverra accumsan. Varius duis at consectetur lorem donec massa. Amet luctus venenatis lectus magna fringilla urna porttitor rhoncus. Mattis aliquam faucibus purus in. Aliquam nulla facilisi cras fermentum odio eu feugiat pretium. In egestas erat imperdiet sed euismod. Mauris pharetra et ultrices neque ornare aenean. Proin fermentum leo vel orci porta. Eget gravida cum sociis natoque penatibus et magnis dis. Suscipit tellus mauris a diam maecenas sed enim ut. Vitae semper quis lectus nulla at volutpat diam ut venenatis. Ipsum consequat nisl vel pretium lectus. Convallis tellus id interdum velit laoreet id donec. Facilisis leo vel fringilla est ullamcorper eget. Elit duis tristique sollicitudin nibh sit amet commodo nulla. Sollicitudin nibh sit amet commodo nulla facilisi nullam vehicula. Ullamcorper morbi tincidunt ornare massa eget egestas purus viverra accumsan. Sit amet luctus venenatis lectus magna. Sit amet nulla facilisi morbi tempus iaculis urna.
<br/>
Fermentum et sollicitudin ac orci. Euismod in pellentesque massa placerat duis ultricies lacus. Consectetur purus ut faucibus pulvinar elementum. Malesuada pellentesque elit eget gravida cum sociis natoque. Arcu bibendum at varius vel pharetra vel turpis nunc. Et tortor consequat id porta nibh venenatis. Gravida dictum fusce ut placerat orci nulla pellentesque dignissim. Accumsan sit amet nulla facilisi morbi tempus iaculis. Vitae proin sagittis nisl rhoncus. Nec nam aliquam sem et tortor consequat id porta nibh. Sollicitudin tempor id eu nisl nunc mi ipsum faucibus vitae. Velit scelerisque in dictum non consectetur a. Scelerisque eleifend donec pretium vulputate sapien nec sagittis aliquam malesuada. Amet nisl purus in mollis nunc sed. Justo donec enim diam vulputate ut pharetra sit. Proin sagittis nisl rhoncus mattis rhoncus urna neque viverra.

Duis tristique sollicitudin nibh sit amet commodo. Sed sed risus pretium quam vulputate dignissim suspendisse in. Nisl nunc mi ipsum faucibus vitae. Risus in hendrerit gravida rutrum quisque non tellus orci. Aliquam etiam erat velit scelerisque in dictum non consectetur a. Eu feugiat pretium nibh ipsum consequat nisl vel. Nunc consequat interdum varius sit amet mattis vulputate enim nulla. Etiam sit amet nisl purus in. Tincidunt augue interdum velit euismod in pellentesque. Arcu dictum varius duis at consectetur. Velit scelerisque in dictum non consectetur a erat nam. Nisl condimentum id venenatis a condimentum vitae sapien pellentesque. A diam maecenas sed enim ut sem viverra aliquet. Lectus proin nibh nisl condimentum id venenatis. Viverra adipiscing at in tellus.
<br/>
<br/>
Enim ut tellus elementum sagittis vitae et leo. Nunc id cursus metus aliquam. Quisque id diam vel quam elementum pulvinar etiam non quam. Sagittis id consectetur purus ut faucibus. Etiam non quam lacus suspendisse faucibus interdum. Sed vulputate mi sit amet mauris commodo quis. Interdum consectetur libero id faucibus nisl tincidunt eget. Blandit volutpat maecenas volutpat blandit aliquam etiam erat. Neque egestas congue quisque egestas diam in arcu. Aliquet risus feugiat in ante metus dictum at. Eget mauris pharetra et ultrices neque. Mi ipsum faucibus vitae aliquet nec ullamcorper. Consequat ac felis donec et odio pellentesque diam. Velit sed ullamcorper morbi tincidunt ornare.

Integer feugiat scelerisque varius morbi enim nunc faucibus. Sapien nec sagittis aliquam malesuada bibendum. Amet volutpat consequat mauris nunc congue nisi vitae. Eget duis at tellus at. Lorem donec massa sapien faucibus et molestie ac feugiat sed. Facilisi morbi tempus iaculis urna id volutpat lacus laoreet non. Nec sagittis aliquam malesuada bibendum arcu. Pellentesque diam volutpat commodo sed. Ut sem nulla pharetra diam. Pulvinar neque laoreet suspendisse interdum consectetur libero id. Id aliquet lectus proin nibh nisl. Velit sed ullamcorper morbi tincidunt ornare. Ipsum dolor sit amet consectetur. Neque vitae tempus quam pellentesque nec nam aliquam sem et. Euismod in pellentesque massa placerat duis.

Vitae congue eu consequat ac. </Typography>
<br/>
<Typography variant="h6" align="left">Posuere urna nec tincidunt praesent semper</Typography>
<br/>
<Typography variant="body1" align="left"> feugiat nibh sed. Elementum nibh tellus molestie nunc non blandit. Habitasse platea dictumst vestibulum rhoncus est pellentesque elit. Phasellus faucibus scelerisque eleifend donec pretium vulputate sapien nec sagittis. Pretium viverra suspendisse potenti nullam ac. Vitae nunc sed velit dignissim sodales. Facilisis sed odio morbi quis commodo. Eget sit amet tellus cras adipiscing enim. Tristique et egestas quis ipsum suspendisse. Augue interdum velit euismod in pellentesque massa. Amet est placerat in egestas erat imperdiet sed euismod nisi. Vestibulum morbi blandit cursus risus at ultrices mi tempus imperdiet. Mollis nunc sed id semper risus.

Pulvinar etiam non quam lacus suspendisse faucibus interdum posuere lorem. Pellentesque nec nam aliquam sem et tortor consequat id porta. Lobortis mattis aliquam faucibus purus. Diam maecenas sed enim ut sem. Erat imperdiet sed euismod nisi porta lorem. Facilisi etiam dignissim diam quis enim. Tempor id eu nisl nunc mi ipsum. Laoreet id donec ultrices tincidunt arcu non sodales. Etiam dignissim diam quis enim. Condimentum vitae sapien pellentesque habitant. Suspendisse faucibus interdum posuere lorem ipsum dolor sit.

Consequat interdum varius sit amet mattis vulputate enim. Feugiat nisl pretium fusce id velit ut tortor. Convallis tellus id interdum velit laoreet. Aliquam etiam erat velit scelerisque in dictum. Iaculis eu non diam phasellus vestibulum. Sed augue lacus viverra vitae congue eu consequat. Sollicitudin tempor id eu nisl nunc mi ipsum. Quam pellentesque nec nam aliquam sem. Nibh tellus molestie nunc non blandit. Facilisis sed odio morbi quis. Massa sed elementum tempus egestas sed sed. Et magnis dis parturient montes nascetur ridiculus. Condimentum lacinia quis vel eros donec. Cursus eget nunc scelerisque viverra mauris in aliquam sem fringilla. Malesuada fames ac turpis egestas maecenas. Egestas diam in arcu cursus euismod quis viverra nibh. Arcu ac tortor dignissim convallis aenean et tortor at. Cursus euismod quis viverra nibh cras. Eget gravida cum sociis natoque penatibus. Nulla at volutpat diam ut.

Eget lorem dolor sed viverra ipsum nunc aliquet bibendum enim. Lorem sed risus ultricies tristique nulla aliquet. Pellentesque sit amet porttitor eget dolor morbi non arcu. Lacinia quis vel eros donec ac odio. Ac felis donec et odio pellentesque diam. Semper feugiat nibh sed pulvinar proin. Rhoncus dolor purus non enim praesent. Tincidunt dui ut ornare lectus sit amet est. At tempor commodo ullamcorper a. Tincidunt tortor aliquam nulla facilisi. At imperdiet dui accumsan sit amet nulla facilisi morbi. Blandit libero volutpat sed cras ornare arcu dui.

Risus feugiat in ante metus dictum at tempor. Viverra justo nec ultrices dui sapien. In hendrerit gravida rutrum quisque non tellus orci ac. Id aliquet risus feugiat in. Ullamcorper dignissim cras tincidunt lobortis feugiat. Varius vel pharetra vel turpis nunc eget. At ultrices mi tempus imperdiet nulla. Rhoncus mattis rhoncus urna neque viverra justo nec ultrices. Sed viverra tellus in hac habitasse. Tristique et egestas quis ipsum suspendisse ultrices gravida dictum fusce. Vestibulum morbi blandit cursus risus at ultrices. Diam ut venenatis tellus in metus. Eu lobortis elementum nibh tellus molestie nunc non blandit massa. Ac tincidunt vitae semper quis lectus nulla at volutpat.
<br/>
Accumsan tortor posuere ac ut consequat. Sed risus ultricies tristique nulla aliquet enim tortor. Justo eget magna fermentum iaculis eu. Vitae suscipit tellus mauris a. Id ornare arcu odio ut. Mi sit amet mauris commodo quis. Ut placerat orci nulla pellentesque. Ipsum consequat nisl vel pretium lectus quam id leo. Habitant morbi tristique senectus et netus. Ut lectus arcu bibendum at varius vel pharetra vel turpis. Morbi tristique senectus et netus et. Posuere urna nec tincidunt praesent semper feugiat. Non tellus orci ac auctor augue mauris augue neque. Vestibulum rhoncus est pellentesque elit ullamcorper. Enim nulla aliquet porttitor lacus luctus accumsan tortor posuere. Vitae ultricies leo integer malesuada nunc vel risus commodo. Feugiat vivamus at augue eget arcu dictum varius.

Mi quis hendrerit dolor magna. Nisl nisi scelerisque eu ultrices vitae auctor eu augue. Egestas purus viverra accumsan in nisl nisi scelerisque eu ultrices. Non blandit massa enim nec. Eu augue ut lectus arcu. Massa id neque aliquam vestibulum morbi blandit cursus. Velit scelerisque in dictum non consectetur. Sit amet venenatis urna cursus eget nunc scelerisque. Adipiscing vitae proin sagittis nisl rhoncus mattis rhoncus urna. Vivamus at augue eget arcu dictum varius duis. Mi proin sed libero enim sed faucibus turpis in. Facilisi nullam vehicula ipsum a arcu cursus vitae. In fermentum et sollicitudin ac orci phasellus egestas. Turpis egestas maecenas pharetra convallis posuere morbi leo. Nunc mattis enim ut tellus elementum sagittis vitae et leo. Ut eu sem integer vitae justo eget magna fermentum. Rhoncus dolor purus non enim praesent elementum facilisis leo vel. Metus aliquam eleifend mi in nulla posuere sollicitudin aliquam. Aliquam ultrices sagittis orci a.

Laoreet sit amet cursus sit amet dictum sit amet. Nullam ac tortor vitae purus faucibus ornare. Fermentum posuere urna nec tincidunt praesent. Sit amet volutpat consequat mauris nunc congue nisi vitae. Fames ac turpis egestas integer eget. Enim nulla aliquet porttitor lacus luctus accumsan tortor posuere. Eget arcu dictum varius duis. Sit amet tellus cras adipiscing. Fusce id velit ut tortor pretium viverra suspendisse potenti. Integer quis auctor elit sed vulputate mi sit. Vitae suscipit tellus mauris a. Faucibus a pellentesque sit amet porttitor eget dolor. Lobortis scelerisque fermentum dui faucibus. Nascetur ridiculus mus mauris vitae ultricies.

Eget aliquet nibh praesent tristique magna sit. Morbi enim nunc faucibus a pellentesque sit amet porttitor. Vitae justo eget magna fermentum iaculis eu non diam. Ultricies leo integer malesuada nunc vel risus. Suspendisse ultrices gravida dictum fusce ut. Commodo sed egestas egestas fringilla. Adipiscing elit ut aliquam purus sit amet luctus venenatis lectus. Purus sit amet luctus venenatis lectus magna fringilla urna porttitor. Tristique senectus et netus et malesuada fames ac turpis. Quis ipsum suspendisse ultrices gravida dictum fusce ut. Aliquam purus sit amet luctus venenatis lectus. Id interdum velit laoreet id donec ultrices. Sit amet consectetur adipiscing elit ut. Pellentesque adipiscing commodo elit at imperdiet dui accumsan sit amet. Vulputate mi sit amet mauris commodo. Libero id faucibus nisl tincidunt eget nullam non nisi est.

Ullamcorper sit amet risus nullam eget. Malesuada pellentesque elit eget gravida cum. Orci porta non pulvinar neque laoreet. Id aliquet lectus proin nibh nisl condimentum. Vestibulum morbi blandit cursus risus at ultrices mi tempus. Sed nisi lacus sed viverra tellus in hac habitasse platea. Dictumst vestibulum rhoncus est pellentesque elit ullamcorper dignissim. Purus in massa tempor nec feugiat nisl. Commodo viverra maecenas accumsan lacus vel facilisis. Sed viverra ipsum nunc aliquet bibendum enim facilisis. Feugiat nisl pretium fusce id velit ut tortor. Vulputate mi sit amet mauris commodo quis imperdiet. Faucibus ornare suspendisse sed nisi lacus sed viverra tellus in.

Dictum fusce ut placerat orci nulla pellentesque dignissim enim. Felis bibendum ut tristique et egestas. Amet purus gravida quis blandit turpis cursus. Ornare lectus sit amet est. Libero justo laoreet sit amet cursus sit. Ultrices dui sapien eget mi proin sed. Sed lectus vestibulum mattis ullamcorper velit sed ullamcorper morbi. Morbi tristique senectus et netus et malesuada fames ac. Risus nullam eget felis eget. Dignissim cras tincidunt lobortis feugiat vivamus at augue. Bibendum neque egestas congue quisque. Metus dictum at tempor commodo ullamcorper a.

Faucibus pulvinar elementum integer enim neque volutpat ac. Nam libero justo laoreet sit amet cursus. A scelerisque purus semper eget duis. Suspendisse in est ante in nibh. At risus viverra adipiscing at. Feugiat in fermentum posuere urna nec tincidunt praesent semper feugiat. Risus nec feugiat in fermentum. Condimentum lacinia quis vel eros. Integer feugiat scelerisque varius morbi enim nunc faucibus a. Tincidunt lobortis feugiat vivamus at augue. Iaculis nunc sed augue lacus viverra vitae congue eu consequat. Velit euismod in pellentesque massa placerat duis ultricies. Nibh mauris cursus mattis molestie a iaculis.

Nullam vehicula ipsum a arcu cursus vitae congue mauris. Ridiculus mus mauris vitae ultricies leo integer. Pulvinar neque laoreet suspendisse interdum consectetur libero id. Amet aliquam id diam maecenas ultricies. Orci porta non pulvinar neque laoreet suspendisse interdum consectetur libero. Aliquam ultrices sagittis orci a scelerisque purus semper eget duis. Sed felis eget velit aliquet sagittis. Augue interdum velit euismod in. Nibh praesent tristique magna sit amet purus. Sit amet nulla facilisi morbi tempus iaculis urna id volutpat. Velit laoreet id donec ultrices tincidunt arcu non.
<br/>
Erat imperdiet sed euismod nisi porta lorem mollis aliquam. Sit amet volutpat consequat mauris nunc congue. Scelerisque in dictum non consectetur a erat nam at lectus. Laoreet non curabitur gravida arcu ac tortor dignissim. Ultricies mi eget mauris pharetra et ultrices. Sed egestas egestas fringilla phasellus faucibus scelerisque eleifend donec pretium. Massa ultricies mi quis hendrerit dolor magna eget est. Tellus in hac habitasse platea dictumst. Eget nunc lobortis mattis aliquam faucibus purus in massa tempor. Mi eget mauris pharetra et ultrices neque ornare aenean euismod. Metus vulputate eu scelerisque felis imperdiet proin. A condimentum vitae sapien pellentesque habitant morbi. Aenean pharetra magna ac placerat. Aliquet nec ullamcorper sit amet risus nullam. Neque vitae tempus quam pellentesque nec. Mauris rhoncus aenean vel elit. Consectetur adipiscing elit ut aliquam purus sit. Elit pellentesque habitant morbi tristique. In mollis nunc sed id semper risus in hendrerit. Nec feugiat nisl pretium fusce id velit.

Et ultrices neque ornare aenean. Sit amet volutpat consequat mauris nunc congue nisi. Mattis enim ut tellus elementum. Mauris ultrices eros in cursus turpis massa tincidunt dui. Risus nullam eget felis eget nunc lobortis mattis aliquam. Vitae elementum curabitur vitae nunc sed velit dignissim sodales. Ante metus dictum at tempor commodo. Iaculis urna id volutpat lacus laoreet non curabitur. Lectus proin nibh nisl condimentum id venenatis a. Tristique senectus et netus et malesuada fames ac turpis. Pharetra convallis posuere morbi leo urna molestie at elementum.

Lobortis mattis aliquam faucibus purus in. Facilisi morbi tempus iaculis urna. Venenatis tellus in metus vulputate. Aliquam id diam maecenas ultricies. Sit amet mattis vulputate enim nulla aliquet porttitor. Duis ut diam quam nulla. Enim sed faucibus turpis in eu. Eleifend quam adipiscing vitae proin sagittis nisl rhoncus. Volutpat commodo sed egestas egestas fringilla phasellus faucibus scelerisque eleifend. Libero volutpat sed cras ornare arcu dui vivamus arcu. Aenean sed adipiscing diam donec adipiscing tristique risus nec feugiat. Vitae et leo duis ut diam quam nulla porttitor massa. Senectus et netus et malesuada fames ac turpis egestas sed. Tristique nulla aliquet enim tortor at auctor. Ut aliquam purus sit amet luctus venenatis.
            </Typography>
        </Container>
        )
    }
export default LicenseAgreement;