import { all } from 'redux-saga/effects';
import UserSaga from './UserSaga';
import fileSaga from './FileSaga';
import companySaga from './CompanySaga';
import invoiceSaga from './InvoiceSaga';
import LicenseSaga from './LicenseSaga';
import RulerSage from './RulerSaga';
// TODO: Add in Company Saga
export default function* rootSaga() {
    yield all([
        UserSaga(),
        fileSaga(),
        companySaga(),
        invoiceSaga(),
        LicenseSaga(),
        RulerSage(),
    ])
};