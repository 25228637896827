import React, { useEffect } from 'react';
import {
    Box,
    Card,
    Button,
    Container,
    TextField,
    Typography,
    FormControlLabel,
    Checkbox,
    Chip,    
} from '@mui/material';
import {
    CheckCircle,
    AlertCircle,
} from 'react-feather';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from "react-redux";
import CreateCompany from './CreateCompany';
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import getToken from '../../mixins/GetToken';

const BusinessInfo = () => {
    const currentCompany = useSelector((state) => state.CompanyReducer?.userCompanies[0]);
    const user = useSelector((state) => state.UserReducer.isAuthenticated);
    const [edit, setEdit] = React.useState(true);
    const dispatch = useDispatch();
    console.log("HERE",currentCompany);

    const [name, setName] = React.useState(currentCompany?.name);
    const [street, setStreet] = React.useState(currentCompany?.street);
    const [city, setCity] = React.useState(currentCompany?.city);
    const [state, setState] = React.useState(currentCompany?.state);
    const [zip, setZip] = React.useState(currentCompany?.zip);
    const [country, setCountry] = React.useState(currentCompany?.country);
    const [email, setEmail] = React.useState(currentCompany?.email);
    const [accountingEmail, setAccountingEmail] = React.useState(currentCompany?.accountingEmail);
    const [phoneNumber, setPhoneNumber] = React.useState(currentCompany?.phoneNumber);

    const [processStatus, setProcessStatus] = React.useState(currentCompany?.processExternal);
    console.log(processStatus)
    // console.log(currentCompany)
    let company = {
        Id: currentCompany?.id,
        Name: name,
        City: city,
        Country: country,
        State: state,
        Street: street,
        Zip: zip,
        ProcessExternal: processStatus,
        Email: email,
        PhoneNumber: phoneNumber,
        CompanyStatus: currentCompany?.companyStatus,
        AccountingEmail: accountingEmail
    };
    
    /// change company processing status
    function changeProcessExternalStatus(){
        if (currentCompany?.processExternal === false) {
            // console.log("set true")
            setProcessStatus(true);
            company = {
                Id: currentCompany?.id,
                Name: currentCompany?.name,
                City: currentCompany?.city,
                Country: currentCompany?.country,
                State: currentCompany?.state,
                Street: currentCompany?.street,
                Zip: currentCompany?.zip,
                ProcessExternal: true,
                Email: currentCompany?.email,
                PhoneNumber: currentCompany?.phoneNumber,
                CompanyStatus: currentCompany?.companyStatus,
                AccountingEmail: currentCompany?.accountingEmail
            }
            if ( company?.ProcessExternal === true){
                dispatch({ type: "CHANGE_COMPANY_STATUS", payload: company});
            }
        }
        else if (currentCompany?.processExternal === true) {
            setProcessStatus(false)
            company = {
                Id: currentCompany?.id,
                Name: currentCompany?.name,
                City: currentCompany?.city,
                Country: currentCompany?.country,
                State: currentCompany?.state,
                Street: currentCompany?.street,
                Zip: currentCompany?.zip,
                ProcessExternal: false,
                Email: currentCompany?.email,
                PhoneNumber: currentCompany?.phoneNumber,
                CompanyStatus: currentCompany?.companyStatus,
                AccountingEmail: currentCompany?.accountingEmail
            }
            if ( company?.ProcessExternal === false){
                dispatch({ type: "CHANGE_COMPANY_STATUS", payload: company});
            }        
        }
    }

    function onSubmit(){
        dispatch({ type: "UPDATE_COMPANY_OBJECT", payload: company});
        console.log(company);
    }
    console.log(accountingEmail)
    console.log(email)

    React.useEffect(() => {
    }, [processStatus, currentCompany])

    /// check company status to render display
    if (currentCompany?.companyStatus == 0 ||
        currentCompany?.companyStatus == 1 ||
        currentCompany?.companyStatus == 2 ){
        return (
            <Box
                display="flex"
                flexDirection="column"
                height="100%"
                justifyContent="center"
                p={3}
            >
                <Container maxWidth="sm">
                    { currentCompany?.companyStatus == 0 &&
                        <div>
                            <h3>Company Credit Status</h3>
                            <Chip icon={<AlertCircle/>} variant="outlined" color="error" label="CREDIT"/>
                            <h5>Company Approval Currently Pending. For additional information contact Botmark.</h5>
                        </div>
                    }
                    { currentCompany?.companyStatus == 1 &&
                        <div>
                            <h3>Company Credit Status</h3>
                            <Chip icon={<CheckCircle/>} variant="contained" color="success" label="CREDIT"/>
                        </div>
                    }
                    { currentCompany?.companyStatus == 2 &&
                        <div>
                            <h3>Company Credit Status</h3>
                            <Chip icon={<AlertCircle/>} variant="outlined" color="error" label="CREDIT"/>
                            <h5>For additional information contact Botmark.</h5>
                        </div>
                    }
                    { currentCompany?.companyStatus == 3 &&
                        <div>
                            <h3>Company Credit Status</h3>
                            <Chip icon={<AlertCircle/>} variant="outlined" color="error" label="CREDIT"/>
                            <h5>For additional information contact Botmark.</h5>
                        </div>
                    }
                            <Form>
                                <div style={{display:'flex', marginTop:'10px'}}>
                                    <Label style={{margin:'auto', marginRight:'10px', width:'20%'}}>Name:</Label>
                                    <TextField
                                        fullWidth
                                        onChange={(event)=>setName(event.target.value)}
                                        value={name}
                                    />
                                </div>
                                <div style={{display:'flex', marginTop:'10px'}}>
                                    <Label style={{margin:'auto', marginRight:'10px', width:'20%'}}>Street:</Label>
                                    <TextField
                                        fullWidth
                                        onChange={(event)=>setStreet(event.target.value)}
                                        value={street}
                                    />
                                </div>
                                <div style={{display:'flex', marginTop:'10px'}}>
                                    <Label style={{margin:'auto', marginRight:'10px', width: '20%'}}>City:</Label>
                                    <TextField
                                        fullWidth
                                        onChange={(event)=>setCity(event.target.value)}
                                        value={city}
                                    />
                                </div>
                                <div style={{display:'flex', marginTop:'10px'}}>
                                    <Label style={{margin:'auto', marginRight:'10px', width: '20%'}}>State:</Label>
                                    <TextField
                                        fullWidth
                                        onChange={(event)=>setState(event)}
                                        value={state}
                                    />
                                </div>
                                <div style={{display:'flex', marginTop:'10px'}}>
                                    <Label style={{margin:'auto', marginRight:'10px', width: '20%'}}>Zip:</Label>
                                    <TextField 
                                        fullWidth
                                        onChange={(event)=>setZip(event.target.value)}
                                        value={zip}
                                    />
                                </div>
                                <div style={{display:'flex', marginTop:'10px'}}>
                                    <Label style={{margin:'auto', marginRight:'10px', width:'20%'}}>Country:</Label>
                                    <TextField
                                        fullWidth
                                        onChange={(event)=>setCountry(event.target.value)}
                                        value={country}
                                    />
                                </div>
                                <div style={{display:'flex', marginTop:'10px'}}>
                                    <Label style={{margin:'auto', marginRight:'10px', width:'20%'}}>Email:</Label>
                                    <TextField
                                        fullWidth
                                        onChange={()=>setEmail()}
                                        value={email}
                                    />
                                </div>
                                <div style={{display:'flex', marginTop:'10px'}}>
                                    <Label style={{margin:'auto', marginRight:'10px',  width: '20%'}}>Accounting Email:</Label>
                                    <TextField
                                        fullWidth
                                        onChange={(event)=>setAccountingEmail(event.target.value)}
                                        value={accountingEmail}
                                    />
                                </div>
                                <div style={{display:'flex', marginTop: '10px'}}>
                                    <Label style={{margin:'auto', marginRight:'10px', width: '20%'}}>Phone:</Label>
                                    <TextField
                                        fullWidth
                                        onChange={(event)=>setPhoneNumber(event.target.value)}
                                        value={phoneNumber}
                                    />
                                </div>
                                <Button variant="contained"
                                    fullWidth sx={{ marginTop: '5px', height: '3.4375em', padding: '16.5px 14px' }}
                                    color="primary"
                                    onClick={()=>onSubmit()}
                                    >
                                    Update Company Info
                                </Button>
                            </Form>
                    {currentCompany.id != '00000000-0000-0000-0000-000000000000' && currentCompany.companyStatus == 0 ?
                        <div>   
                            <h1>Company currently pending Approval.</h1>
                        </div>
                    :
                        <div>
                            <h3>PROCESS EXTERNAL</h3>
                            { user.theUserRoles[0] != "AD" ?
                            <p>To change if this company is visible to prospective customers please talk to a {currentCompany.name} Admin or Botmark Administrator.</p>
                            :
                            <div>
                                <p>If your company wants to be visible to prospective customers then make sure the box below is checked.</p>
                                <Form>
                                    <Label check>
                                        <Input type="switch" role="switch" checked={processStatus} value={currentCompany?.processExternal} onChange={() => changeProcessExternalStatus()}/>
                                        Processing External Plans
                                    </Label>
                                </Form>
                            </div>
                            }
                        </div>
                    }
                </Container>
            </Box>
        )
    } else if(currentCompany?.id != '00000000-0000-0000-0000-000000000000' && currentCompany?.companyStatus == 3){
        return(
            <div>   
                <h1>Company creation suspended, for more information contact Botmark for more information.</h1>
            </div>
        )
    }
    else {
        return <CreateCompany/>
    }
};

export default BusinessInfo;