import React, { Component } from 'react';
import { useDispatch, connect } from 'react-redux';
import './App.css';
import MainLayout from './layout';

class App extends Component {
    render() {
        return (
            <div className="App">
                <MainLayout/>
            </div>
        );
    }
}

export default connect()(App);
