import React , { useState } from 'react';
import {
    Box,
    Button,
    Container,
    TextField,
    IconButton,
    InputAdornment,
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import Toast from '../../components/Toast';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const UserInfo = () => {
    const user = useSelector((state) => state.UserReducer.user);
    const [edit, setEdit] = React.useState(true);
    const dispatch = useDispatch();

    const [showPassword1, setShowPassword1] = useState(false);
    const handleClickShowPassword1 = () => setShowPassword1(!showPassword1);
    const handleMouseDownPassword1 = () => setShowPassword1(!showPassword1);
    const [showPassword2, setShowPassword2] = useState(false);
    const handleClickShowPassword2 = () => setShowPassword2(!showPassword2);
    const handleMouseDownPassword2 = () => setShowPassword2(!showPassword2);

    /// user account information page
    return (
        <Box
            display="flex"
            flexDirection="column"
            height="100%"
            justifyContent="center"
            p={3}
        >
            <Container maxWidth="sm">
                <Formik
                    initialValues={{
                        UserName: user.userName,
                        Email: user.email,
                        FirstName: user.firstName,
                        LastName: user.lastName
                    }}
                    validationSchema={
                        Yup.object().shape({
                            UserName: Yup.string().max(255).required('Username is required'),
                            Email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                            FirstName: Yup.string().max(255).required('First name is required'),
                            LastName: Yup.string().max(255).required('Last name is required'),
                        })
                    }
                    onSubmit={(values) => {
                        // update user
                        dispatch({type: "UPDATE_USER_INFO", payload: {user: values, userId: user.id}});
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <TextField
                                error={Boolean(touched.UserName && errors.UserName)}
                                fullWidth
                                helperText={touched.UserName && errors.UserName}
                                label="Username"
                                margin="normal"
                                name="UserName"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.UserName}
                                variant="outlined" 
                            />
                            <TextField
                                error={Boolean(touched.FirstName && errors.FirstName)}
                                fullWidth
                                helperText={touched.FirstName && errors.FirstName}
                                label="First Name"
                                margin="normal"
                                name="FirstName"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.FirstName}
                                variant="outlined"
                            />
                            <TextField
                                error={Boolean(touched.LastName && errors.LastName)}
                                fullWidth
                                helperText={touched.LastName && errors.LastName}
                                label="Last Name"
                                margin="normal"
                                name="LastName"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.LastName}
                                variant="outlined"
                            />
                            <TextField
                                error={Boolean(touched.Email && errors.Email)}
                                fullWidth
                                helperText={touched.Email && errors.Email}
                                label="Email"
                                margin="normal"
                                name="Email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.Email}
                                variant="outlined"
                            />
                            <Box>
                                <Button 
                                    variant="contained" 
                                    type="submit"
                                    fullWidth sx={{ marginTop: '5px', height: '3.4375em', padding: '16.5px 14px' }}>
                                    Save Changes
                                </Button>
                            </Box>
                        </form>
                    )}
                </Formik>
                <Formik
                    initialValues={{
                        Password: '',
                        Confirm: ''
                    }}
                    validationSchema={
                        Yup.object().shape({
                            Password: Yup.string().max(255).required('Password is required'),
                            Confirm: Yup.string().max(255).required('Confirm password').oneOf([Yup.ref('Password'), null], 'Passwords must match')
                        })
                    }
                    onSubmit={(values) => {
                        // update user
                        if (values.Password != values.Confirm){
                            Toast.fire({
                                icon: 'error',
                                title: 'Passwords Must Match'
                            });
                        }
                        if (values.Password === values.Confirm){
                            dispatch({type: "UPDATE_USER_PASSWORD", payload: {user: values, userId: user.id}});
                        }
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values
                    }) => (
                    <form onSubmit={handleSubmit}>
                  <TextField
                                error={Boolean(touched.Password && errors.Password)}
                                fullWidth
                                helperText={touched.Password && errors.Password}
                                label="Password"
                                margin="normal"
                                name="Password"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type={showPassword1 ? "text" : "password"}                                
                                value={values.Password}
                                variant="outlined"
                                InputProps={{ // <-- This is where the toggle button is added.
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowPassword1}
                                          onMouseDown={handleMouseDownPassword1}
                                        >
                                          {showPassword1 ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                      </InputAdornment>
                                )}}
                            />
                            <TextField
                                error={Boolean(touched.Confirm && errors.Confirm)}
                                fullWidth
                                helperText={touched.Confirm && errors.Confirm}
                                label="Confirm Password"
                                margin="normal"
                                name="Confirm"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type={showPassword2 ? "text" : "password"}                                
                                value={values.Confirm}
                                variant="outlined"
                                InputProps={{ // <-- This is where the toggle button is added.
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowPassword2}
                                          onMouseDown={handleMouseDownPassword2}
                                        >
                                          {showPassword2 ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                      </InputAdornment>
                                )}}
                            />
                        <Box>
                            <Button variant="contained" type="submit">
                                Submit New Password
                            </Button>
                        </Box>
                    </form>
                    )}
                </Formik>
            </Container>
        </Box>
    )
};

export default UserInfo;