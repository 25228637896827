import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

function PDFDisplay(pdf){
    /// Viewer will display PDF file for view on client
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
  
    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
      setPageNumber(1);
    };

    return(
        <div className="main">
            <Document file={{url: pdf}} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} />
            </Document>
        </div>
    )
};

export default PDFDisplay;