import React from "react";
import {
    Button,
    FormControl,
    OutlinedInput,
    InputAdornment,
    InputLabel
} from "@mui/material";
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";

function PriceOptions (){
    const userCompany = useSelector((state) => state.CompanyReducer.userCompanies);
    const CompanyPrices = useSelector((state) => state.CompanyReducer.CompanyPrices);
    const userData = useSelector((state)=> state.UserReducer.user);
    const [itemName, setItemName] = React.useState();
    const [description, setDescription] = React.useState();
    const [unit, setUnit] = React.useState();
    const [rate, setRate] = React.useState();
    const [isTax, setIsTax] = React.useState(false);
    const [isPrimary, setIsPrimary] = React.useState(false);

    const dispatch = useDispatch();

    let companyId = userCompany[0].id;
    let price = {
        CompanyId: companyId,
        ItemName: itemName,
        Description: description,
        Unit: unit,
        Rate: rate,
        isForTaxes: isTax,
        isBotmarkPrimary: isPrimary
    };
    
    ///create new price option
    function submitPrices(){
        dispatch({ type: "POST_PRICE", payload: price, companyId });
        setItemName();
        setDescription();
        setUnit();
        setRate();
        setIsTax(false);
        setIsPrimary(false);
    };

    React.useEffect(() => {
        dispatch({ type: 'GET_PRICES', payload: companyId });
        if (userData.isAdmin == true){
            dispatch({ type: 'FIND_BOTMARK_PRICES' });
        }
    },[userCompany]);

    return(
        <div>
            <Form style={{width:"100%", margin: 'auto'}}>
                <Label>Create a Pricing Option to be used when crafting a invoice.</Label>
                <br/>
                <FormControl sx={{ m: 1 }}>
                    <InputLabel htmlFor="outlined-adornment-amount">Item</InputLabel>
                    <OutlinedInput
                        multiline
                        rows={1}
                        value={itemName}
                        onChange={(event)=>setItemName(event.target.value)}
                        id="outlined-adornment-amount"
                        label="Item"
                    />
                </FormControl>
                <FormControl sx={{ m: 1 }}>
                    <InputLabel htmlFor="outlined-adornment-amount">Description</InputLabel>
                    <OutlinedInput
                        multiline
                        rows={4}
                        value={description}
                        onChange={(event)=>setDescription(event.target.value)}
                        id="outlined-adornment-amount"
                        label="Point Price Info"
                    />
                </FormControl>
                <FormControl sx={{ m: 1 }}>
                    <InputLabel htmlFor="outlined-adornment-amount">Units</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-amount"
                        label="Units"
                        rows={1}
                        multiline
                        value={unit}
                        onChange={(event)=>setUnit(event.target.value)}
                    />
                </FormControl>
                <FormControl sx={{ m: 1 }}>
                    <InputLabel htmlFor="outlined-adornment-amount">Rate</InputLabel>
                    <OutlinedInput
                        type="number"
                        id="outlined-adornment-amount"
                        startAdornment={<InputAdornment position="start"></InputAdornment>}
                        label="Rate"
                        value={rate}
                        onChange={(event)=>setRate(event.target.value)}
                    />
                </FormControl>
                <br/>
                <Label></Label>
                <FormControl sx={{ m: 1 }}>
                    <Label>If applicable, mark this box to allow for this pricing option to be saved as a tax option for invoices.</Label>
                    <Label check>
                        <Input type="switch" role="switch" 
                            checked={isTax} 
                            value={isTax}
                             onChange={() => setIsTax(!isTax)}
                        />
                            Is For Taxes
                    </Label>
                </FormControl>
                <br/>
                { userData.isAdmin == true ?
                    <FormControl sx={{ m: 1 }}>
                        <Label>If applicable, mark this box to designate as a Botmark Primary Price Option.</Label>
                        <Label check>
                            <Input type="switch" role="switch" 
                                checked={isPrimary} 
                                value={isPrimary}
                                onChange={() => setIsPrimary(!isPrimary)}
                            />
                                Botmark Primary Price Option
                        </Label>
                    </FormControl>
                    :
                    <br/>
                }
                <br/>
                <Button color="primary" variant="contained" onClick={()=>submitPrices()}>Set Prices</Button>
            </Form>
        </div>
    )
}

export default PriceOptions;