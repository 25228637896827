import { combineReducers } from 'redux';
import UserReducer from './UserReducer';
import FileReducer from './FileReducer';
import CompanyReducer from './CompanyReducer';
import LicenseReducer from './LicenseReducer';
import RulerReducer from './RulerReducer';

const appReducer = combineReducers({
    UserReducer,
    FileReducer,
    CompanyReducer,
    LicenseReducer,
    RulerReducer,
});

const rootReducer = (state, action) => {
    if (action.type == 'LOGGED_OUT') {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;