import PriceOptions from "./PriceOptions";
import PriceOptionsDatagrid from "./PriceOptionsDatagrid";

function PriceOptionsTab (){
    ///for tab separation in account page, should me this easy to edit structure if needed
return(
    <div>
        <PriceOptions/>
        <PriceOptionsDatagrid/>
    </div>
)
}

export default PriceOptionsTab;