import React, { useState, createRef } from 'react';
import { useDispatch } from 'react-redux';
import {
    Form,
    FormGroup,
    Label,
    Col,
    Input,
} from "reactstrap";
import "./Login.css"
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import {
    Box,
    Button,
    Container,
    TextField,
    Typography,
    Link,
    Backdrop,
    CircularProgress
  } from "@mui/material";

const ForgotPassword = () => {
    const [newUserPassword, setNewUserPassword] = useState('');
    const [vNewUserPassword, setVNewUserPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [errortext, setErrortext] = useState('');
    const dispatch = useDispatch();

    const passwordInputRef = createRef();
    const navigate = useNavigate();

    const handleSubmitButton = () => {
        setErrortext('');

        if (!newUserPassword) {
            swal({
                text: 'Please fill In Your New Password',
                icon: "error"
            });
            return;
        }

        if (!vNewUserPassword) {
            swal({
                text: 'Please Verify Your New Password',
                icon: "error"
            });
            return;
        }

        if (vNewUserPassword !== newUserPassword) {
            swal({
                text: 'Passwords do not match, please try again.',
                icon: "error"
            });
            return;
        }

        const urlString = window.location.href;
        const url = new URL(urlString);
        const token = url.searchParams.get("token");

        console.log(urlString);
        console.log(url);
        console.log(token);

        setLoading(true);
        let dataToSend = { ConfirmToken: token, NewPassword: newUserPassword };
        let formBody = [];
        for (let key in dataToSend) {
            let encodedKey = encodeURIComponent(key);
            let encodedValue = encodeURIComponent(dataToSend[key]);
            formBody.push(encodedKey + '=' + encodedValue);
        }

        dispatch({ type: 'FORGOT_PASSWORD', payload: dataToSend, navigate});
    };

    return (
        <div>
            <Form className='registerForm' onSubmit={handleSubmitButton}>
                <h1 className='noticeHeader'>
                <Typography color="textPrimary" variant="h2">Forgot Password</Typography>
                </h1>
                <Col>
                    <TextField
                            fullWidth
                            label="New Password"
                            margin="normal"
                            name="password"
                            onChange={(event) => setNewUserPassword(event.target.value)}
                            type="password"
                            value={newUserPassword}
                            variant="outlined"/>
                </Col>
                <Col>
                    <TextField
                            fullWidth
                            label="Verify New Password"
                            margin="normal"
                            name="password"
                            onChange={(event) => setVNewUserPassword(event.target.value)}
                            type="password"
                            value={vNewUserPassword}
                            variant="outlined"/>
                </Col>
                <FormGroup row>
                    <Button className="registerBtn" onClick={handleSubmitButton}>Submit</Button>
                </FormGroup>
            </Form>
        </div>
    );
};

export default ForgotPassword;