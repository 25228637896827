import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Button,
    TextField,
} from '@mui/material';

const ValidationFileName = React.forwardRef((props, ref) => {
    /// file requires functional name for verification and sale this componant will assign the new name to the file
    const file = useSelector((state) => state.FileReducer.file);
    const [formClientName, setFormClientName] =  React.useState('');
    const [formJobName, setFormJobName] =  React.useState('');
    const [formAreaName, setFormAreaName] =  React.useState('');
    const [formFloorName, setFormFloorName] =  React.useState('');
    const [formPlanName, setFormPlanName] =  React.useState('');

    const { close, companyId, setClientName, setJobName, setAreaName, setFloorName, setPlanName, handleUpload, fileName, clientName, jobName, areaName, floorName, planName, setFileName } = props;
    const company = useSelector((state) => state.CompanyReducer.userCompanies[0]);

    if (formClientName != '' && !formClientName?.includes('_')){
        setClientName(formClientName+`_`)
        setFileName(`${clientName}${jobName}${areaName}${floorName}${planName}BM.txt`);
    };
    if (formClientName != '' && formClientName?.includes('_')){
        setFileName(`${clientName}${jobName}${areaName}${floorName}${planName}BM.txt`);
    };
    if (formClientName == ''){
        setClientName('')
    };
    if (formJobName != '' && !formJobName?.includes('_')){
        setJobName(formJobName+`_`)
        setFileName(`${clientName}${jobName}${areaName}${floorName}${planName}BM.txt`);
    };
    if (formJobName != '' && formJobName?.includes('_')){
        setFileName(`${clientName}${jobName}${areaName}${floorName}${planName}BM.txt`);
    };
    if (formJobName == ''){
        setJobName('')
    };
    if (formAreaName != '' && !formAreaName?.includes('_')){
        setAreaName(formAreaName+`_`)
        setFileName(`${clientName}${jobName}${areaName}${floorName}${planName}BM.txt`);
    };
    if (formAreaName != '' && formAreaName?.includes('_')){
        setFileName(`${clientName}${jobName}${areaName}${floorName}${planName}BM.txt`);
    };
    if (formAreaName == ''){
        setAreaName('')
    };
    if (formFloorName != '' && !formFloorName?.includes('_')){
        setFloorName(formFloorName+`_`)
        setFileName(`${clientName}${jobName}${areaName}${floorName}${planName}BM.txt`);
    };
    if (formFloorName != '' && formFloorName?.includes('_')){
        setFileName(`${clientName}${jobName}${areaName}${floorName}${planName}BM.txt`);
    };
    if (formFloorName == ''){
        setFloorName('')
    };
    if (formPlanName != '' && !formPlanName?.includes('_')){
        setPlanName(formPlanName+`_`)
        setFileName(`${clientName}${jobName}${areaName}${floorName}${planName}BM.txt`);
    };
    if (formPlanName != '' && formPlanName?.includes('_')){
        setFileName(`${clientName}${jobName}${areaName}${floorName}${planName}BM.txt`);
    };    
    if (formPlanName == ''){
        setPlanName('')
    };
    setFileName(`${clientName}${jobName}${areaName}${floorName}${planName}BM.txt`);

return(
    <Box p={3} sx={{ width: '100%', height: '100%', justifyContent:'center' }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    flexDirection: 'column'
                }}>
                    <h2>File Name</h2>
                    <h4>{file.info.name}</h4>
                    <br/>
                    <div style={{display:'flex', flexDirection:'row'}}>
                        <TextField 
                            variant='outlined'
                            label='Client'
                            sx={{ flex:1, margin:1 }}
                            disabled={companyId === undefined}
                            value={formClientName}
                            onChange={(event) => setFormClientName(event.target.value)}  
                        />
                        <TextField 
                            variant='outlined'
                            label='Job'
                            required
                            sx={{flex:1, margin:1}}
                            disabled={companyId === undefined}
                            value={formJobName}
                            onChange={(event) => setFormJobName(event.target.value)}  
                        />
                        <TextField 
                            variant='outlined'
                            label='Area'
                            sx={{flex:1, margin:1}}
                            disabled={companyId === undefined}
                            value={formAreaName}
                            onChange={(event) => setFormAreaName(event.target.value)}  
                        />
                        <TextField 
                            variant='outlined'
                            label='Floor'
                            required
                            sx={{ flex:1, margin:1}}
                            disabled={companyId === undefined}
                            value={formFloorName}
                            onChange={(event) => setFormFloorName(event.target.value)}  
                        />
                        <TextField 
                            variant='outlined'
                            label='Plan'
                            sx={{ flex: 1, margin:1}}
                            disabled={companyId === undefined}
                            value={formPlanName}
                            onChange={(event) => setFormPlanName(event.target.value)}  
                        />
                    </div>
                    <Button sx={{ width: '50%', margin: 5, }} disabled={companyId === undefined} variant="contained" onClick={handleUpload} >Update File Name</Button>
                </Box>
    </Box>
)});

export default ValidationFileName;