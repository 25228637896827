import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { 
    Box,
    Container,
    TextField,
    Typography
 } from "@mui/material";
import { ErrorSharp } from "@mui/icons-material";

const PaymentView = () => {
    /// currently not in use, may delete later
    return (
        <Container maxWidth="sm">
            <Typography variant="h4">
                Card info
            </Typography>
            <Formik
                initialValues={{
                    nameOnCard: '',
                    cardNumber: '',
                    expDate: '',
                }}
                validationSchema={Yup.object().shape({
                    nameOnCard: Yup.string().max(255).required("Name required"),
                    cardNumber: Yup.string().max(255).required("Card number required"),
                })}
                onSubmit={(values) => {
                    console.log(values)
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values                    
                }) => (
                    <form>
                        <TextField 
                            error={Boolean(touched.nameOnCard && errors.nameOnCard)}
                            helperText={touched.nameOnCard && errors.nameOnCard}
                            label="Name on card"
                            margin="normal"
                            name="nameOnCard"
                            onBlur={handleBlur}
                            type="text"
                            value={values.nameOnCard}
                            variant="standard"
                            onChange={handleChange}
                        />
                        <TextField
                            error={Boolean(touched.cardNumber && errors.cardNumber)}
                            helperText={touched.cardNumber && errors.cardNumber}
                            label="Card number"
                            margin="normal"
                            name="cardNumber"
                            onBlur={handleBlur}
                            type="text"
                            value={values.cardNumber}
                            variant="standard"
                            onChange={handleChange}
                        />
                        <Box>
                            <TextField 
                                error={Boolean(touched.expDate && errors.expDate)}
                                helperText={touched.expDate && errors.expDate}
                                margin="normal"
                                name="expDate"
                                onBlur={handleBlur}
                                type="date"
                                value={values.expDate}
                                variant="standard"
                                onChange={handleChange}
                            />
                        </Box>
                    </form>
                )}
            </Formik>
        </Container>
    )
};

export default PaymentView;