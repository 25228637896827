import React from "react";
import {
    Container,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CurrencyFormat from 'react-currency-format';
import './EstimateView.css';
import { usePDF } from 'react-to-pdf';

function RecievedInvoiceViewer({ item, handleInvoiceClose }) {
    /// Page for viewing any invoices sent to customer or from botmark /// 
    const company = useSelector((state) => state.CompanyReducer.userCompanies[0]);
    const userData = useSelector((state) => state.UserReducer.user);
    const dispatch = useDispatch();

    
    let data = [];
    let invoiceTotal = 0;
    let taxTotal = 0;
    let finalTotal = 0;
    if (item?.invoiceValues != undefined) {
        data = JSON?.parse(item?.invoiceValues)
        
        for (let i = 0; i < data.items?.length; i++) {
            invoiceTotal += data.items[i].total;
        }
        console.log('total', invoiceTotal);
        for (let i = 0; i < data.taxes?.length; i++) {
            taxTotal += Number(data.taxes[i].total);
        }
        finalTotal = taxTotal + invoiceTotal
    };

    let pointList = [];
    if (item?.pointList != undefined) {
        pointList = JSON.parse(item?.pointList);
    };

    console.log("ITEM", item);
    console.log("DATA", data);

    /// For File name selection in invoice ///
    function getLastItem(thePath) {
        if (thePath == null || thePath == "") return "";
        var index = thePath.lastIndexOf('/') + 1;
        return thePath.substring(index);
    }

    /// For Creating and Downloading Invoice as CSV ///
    function exportToCsv() {
        let csvDownloadable = [
            [`${data.date}`, `${item.companyContact}: ${item.name.split('.')[0]}`, `${finalTotal}`]
        ];
        let csvContent = "data:text/csv;charset=utf-8,"
            + csvDownloadable.map(e => e.join(",")).join("\n");

        var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, `${item.companyContact.replace(' ', '_')}-${item.name.split('.')[0]}`);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", `${item.companyContact.replace(' ', '_')}-${item.name.split('.')[0]}`);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    /// For Creating and Downloading Invoice as PDF ///
    const { toPDF, targetRef } = usePDF({ filename: `${item?.companyContact?.replace(' ', '_')}-${item?.name?.split('.')[0]}.pdf` });

    return (
        <Container id="invoice" className='estimatePage' maxWidth="sm">
            <Container ref={targetRef}>
                <Typography variant="h4" align="center">
                    Estimate
                </Typography>
                <Table>
                    <p style={{ display: "flex", justifyContent: 'right', width: "100%" }}>
                        <span style={{ marginRight: 50 }}>Date: {data.date}</span>
                        <span># {data.estimateNumber}</span>
                    </p>
                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: 1, margin: 2 }}>
                            <div style={{ flex: 1, margin: 2 }}>
                                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', padding: 10 }}>
                                    <h4 style={{ display: "flex", justifyContent: 'left' }}>From:</h4>
                                    <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>(Placeholder) Name: {data[0]?.name}</span></span>
                                    <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>(Placeholder) Street: {data[0]?.street}</span></span>
                                    <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>(Placeholder) Address: {data[0]?.city} {data[0]?.state} {data[0]?.zip}</span></span>
                                    <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>(Placeholder) Email: {data[0]?.email}</span></span>
                                    <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>(Placeholder) Phone: {data[0]?.phoneNumber}</span></span>
                                </div>
                            </div>
                            <div style={{ flex: 1, margin: 2 }}>
                                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', padding: 10 }}>

                                    <h4 style={{ display: "flex", justifyContent: 'left' }}>To:</h4>
                                    <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>(Placeholder) User: {data?.firstName} {data?.lastName}</span></span>
                                    <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>(Placeholder) Email: {data?.email}</span></span>
                                    {/* <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Company: {company.name}</span></span> */}
                                    <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>(Placeholder) Customer Phone#?</span></span>
                                    <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>(Placeholder) Customer Adress?</span></span>
                                    <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>(Placeholder) Customer Credit Status?</span></span>
                                    {/* {company.companyStatus === 0 &&
                                        <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Pending</span></span>
                                    }
                                    {company.companyStatus === 1 &&
                                        <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Approved with Credit</span></span>
                                    }
                                    {company.companyStatus === 2 &&
                                        <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Approved with out Credit</span></span>
                                    }
                                    {company.companyStatus === 3 &&
                                        <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Denied</span></span>
                                    } */}
                                    <br />
                                </div>
                            </div>
                        </div>
                        <div style={{ flex: 1, margin: 2 }}>
                        </div>
                        <div style={{ flex: 1, margin: 2 }}>
                            <div style={{ border: '1px black solid' }}>
                                <div>
                                    <p>Plan ID: {item.id}</p>
                                </div>
                                <div>
                                    <p>Conversion Details:</p>
                                    {pointList?.map((item, i) => (
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            {item?.Enabled == true &&
                                                <span style={{ display: "flex", justifyContent: "space-between", width: "75%", margin: 5, borderTop: '1px lightgray solid' }}><span>{item?.Description}</span> <span>Qty: {item?.Quantity}</span></span>
                                            }
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </Table>
                <br />
                <Typography variant="body1">
                    Job Name: {item.fileName.split('.')[0]}<br />
                    {/* Purchase includes: {getLastItem(item.paidFileUrl)}, {getLastItem(item.paidTxtUrl)} <br /> */}
                </Typography>
                <br />
                <Table sx={{ border: "solid" }}>
                    <TableHead>
                        <TableRow><TableCell colSpan={7}></TableCell></TableRow>
                        <TableCell>Item</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Qty</TableCell>
                        <TableCell>U/M</TableCell>
                        <TableCell>Rate</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell></TableCell>
                    </TableHead>
                    <TableBody>
                        {data?.items?.map((item, i) => (
                            <TableRow>
                                <TableCell>{item?.itemName}</TableCell>
                                <TableCell>{item?.description}</TableCell>
                                <TableCell>{item?.quantity}</TableCell>
                                <TableCell>{item?.unit}</TableCell>
                                <TableCell>{item?.rate}</TableCell>
                                <TableCell> <CurrencyFormat value={item?.total} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2}>{item.total}</CurrencyFormat></TableCell>
                            </TableRow>
                        ))}
                        <br />
                        <br />
                        <br />
                        <TableRow><TableCell colSpan={7}></TableCell></TableRow>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            {/* <TableCell>{userCompany[0].name} Invoice Total</TableCell> */}
                            <TableCell><CurrencyFormat value={invoiceTotal} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} /></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        {data?.taxes?.map((item, i) => (
                            <TableRow>
                                <TableCell>{item?.itemName}</TableCell>
                                <TableCell>{item?.description}</TableCell>
                                <TableCell></TableCell>
                                <TableCell>{item?.unit}</TableCell>
                                <TableCell>{item?.rate}</TableCell>
                                <TableCell><CurrencyFormat value={item?.total} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} /></TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell><b>Total</b></TableCell>
                            <TableCell><CurrencyFormat value={finalTotal} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} /></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <br />
            </Container>
            <br />
            <Button onClick={() => exportToCsv()}>Download Estimate as .CSV</Button>
            <Button id="cmd" onClick={() => toPDF()}>Download Estimate as .PDF</Button>
        </Container>
    )
}

export default RecievedInvoiceViewer;