import React from "react";
import {
    Card,
    Box,
    TextField,
    Typography,
    Container,
    Divider,
    Button
} from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Page from "../../components/Page";
import Toast from "../../components/Toast";

const Contact = () => {
    /// contact Botmark form
    return (
        <Page title="Contact">
            <Box sx={{ height: '100vh' }}>
                <Container sx={{ padding: '30px', height: '100%' }}>
                    <Card sx={{ padding: '20px', border: '1px solid black' }}>
                        <Typography variant="h4" p={1}>
                            How can we help?
                        </Typography>
                        <Divider/>
                        <Box>

                                <Formik
                                    initialValues={{
                                        problem: '',
                                        details: '',
                                        firstName: '',
                                        lastName: '',
                                        email: '',
                                        phone: ''
                                    }}
                                    validationSchema={
                                        Yup.object().shape({
                                            problem: Yup.string().max(255).required('Please describe problem'),
                                            details: Yup.string().max(500),
                                            firstName: Yup.string().max(255).required('Please provide you first name'),
                                            lastName: Yup.string().max(255).required('Please provide your last name'),
                                            email: Yup.string().email('Must be a valid email').max(255).required('Please provide email'),
                                            phone: Yup.string().max(25)
                                        })
                                    }
                                    onSubmit={(values) => {
                                        console.log(values)
                                        Toast.fire({ icon: 'error', title: 'Support page not enabled' })
                                        //TODO: hook up to emailer for support
                                    }}
                                >
                                    {({
                                        errors,
                                        handleBlur,
                                        handleChange,
                                        handleSubmit,
                                        isSubmitting,
                                        touched,
                                        values
                                    }) => (
                                        <form onSubmit={handleSubmit}>
                                            <TextField 
                                                error={Boolean(touched.problem && errors.problem)}
                                                fullWidth
                                                helperText={touched.problem && errors.problem}
                                                label="Describe the problem you're having"
                                                name="problem"
                                                margin="normal"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.problem}
                                                variant="outlined"

                                            />
                                            <TextField
                                                error={Boolean(touched.details && errors.details)}
                                                fullWidth
                                                helperText={touched.details && errors.details}
                                                label="Give us the details"
                                                margin="normal"
                                                multiline={true}
                                                rows={6}
                                                name="details"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.details}
                                                variant="outlined"
                                            />
                                            <Box sx={{ paddingTop: '20px' }}>
                                                <Typography textAlign='left'>
                                                    Your contact information
                                                </Typography>
                                            </Box>
                                            <Box sx={{ textAlign: 'left' }}>
                                                <TextField
                                                    error={Boolean(touched.firstName && errors.firstName)}
                                                    label="First name"
                                                    helperText={touched.firstName && errors.firstName}
                                                    margin="normal"
                                                    name="firstName"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    variant="outlined"
                                                    value={values.firstName}
                                                    sx={{ marginRight:'15px' }}
                                                />
                                                <TextField
                                                    error={Boolean(touched.lastName && errors.lastName)}
                                                    label="Last name"
                                                    helperText={touched.lastName && errors.lastName}
                                                    margin="normal"
                                                    name="lastName"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.lastName}
                                                />
                                            </Box>
                                            <Box sx={{ textAlign: 'left' }}>
                                                <TextField
                                                    error={Boolean(touched.email && errors.email)}
                                                    label="Email"
                                                    helperText={touched.email && errors.email}
                                                    margin="normal"
                                                    name='email'
                                                    type="email"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.email}
                                                    sx={{ marginRight: '15px' }}
                                                />
                                                <TextField
                                                    error={Boolean(touched.phone && errors.phone)}
                                                    label="Phone number"
                                                    helperText={touched.phone && errors.phone}
                                                    margin="normal"
                                                    name="phone"
                                                    type="tel"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.phone}
                                                />
                                            </Box>
                                            <Button
                                                variant="contained"
                                                fullWidth
                                                onClick={handleSubmit}
                                                sx={{ marginTop: '15px' }}
                                            >
                                                Send
                                            </Button>
                                        </form>

                                    )}
                                </Formik>
                        </Box>
                    </Card>
                </Container>
            </Box>
        </Page>
    )
};

export default Contact;