import React from "react";
import { Box } from "@mui/material";

const ReviewPayment = () => {
    // currently not used, may delete later
    return (
        <Box>

        </Box>
    )
};

export default ReviewPayment;