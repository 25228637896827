import React from "react";
import {
    Card,
    Button,
    Container,
    TextField,
    Typography,
    Box,
    IconButton
} from "@mui/material";
import * as Yup from 'yup';
import { Formik } from 'formik';
import { XSquare } from "react-feather";

const CheckUser = React.forwardRef((props, ref) => {
    const { close } = props;
    return (
        <Card sx={{ padding: '20px' }}>
            <Box p={3} sx={{ display: 'flex', justifyContent: 'space-between' }} >
                <Typography variant="h4">
                    
                </Typography>
                <IconButton onClick={close} color="primary">
                    <XSquare/>
                </IconButton>
            </Box>
            <Container maxWidth="sm">
                <Formik
                    initialValues={{
                        Email: ''
                    }}
                    validationSchema={
                        Yup.object().shape({
                            Email: Yup.string().email("Must be vaild email").max(255).required("Email Required")
                        })
                    }
                    onSubmit={(values) => {
                        console.log(values);
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <TextField 
                                error={Boolean(touched.Email && errors.Email)}
                                fullWidth
                                helperText={touched.Email && errors.Email}
                                label="Email"
                                margin="normal"
                                name="Email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.Email}
                                variant="outlined"
                            />
                            <Box my={2}>
                                <Button
                                    fullWidth
                                    onClick={handleSubmit}
                                    variant="contained"
                                >
                                    Submit
                                </Button>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Container>
        </Card>
    )
});

export default CheckUser;