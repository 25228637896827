import React, { useEffect } from "react";
import {
    Box,
    Tab
} from '@mui/material';
import {
    TabContext,
    TabList,
    TabPanel
} from '@mui/lab';
import UserInfo from "./UserInfo";
import Payment from "./Payment";
import Page from '../../components/Page';
import CompaniesTable from "./Companies";
import BusinessInfo from "./BusinessInfo";
import PriceOptionsTab from "./PriceOptions/PriceOptionsTab";
import { useDispatch, useSelector } from "react-redux";
import UserPage from "../users/index";
import LicenseTab from "./LicenseTab";

const ManageAccount = () => {
    const [value, setValue] = React.useState('1');
    const dispatch = useDispatch();

    const handleChange= ( event, newValue ) => {
        setValue(newValue)
    };
    const user = useSelector((state) => state.UserReducer.isAuthenticated);
    const userCompany = useSelector((state) => state.CompanyReducer.userCompanies);

    console.log("HERE IS THE NAME", userCompany[0]?.name)

    React.useEffect(() => {
        // dispatch({ type: 'GET_PRICES', payload: userCompany[0].id });
        dispatch({ type: 'GET_LICENSES', payload: 1 });
    },[userCompany]);

    /// Display for Account page
    return (
        <Page title="Account">
            <Box p={3}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'center' }}>
                        <TabList onChange={handleChange}>
                            {/* { user.theUserRoles[0] == "AD" || user.theUserRoles[0] == "DAD" ?
                                <Tab label="Payment History" value="1"/>
                                :
                                <p></p>
                            } */}
                            <Tab label="User Info" value="1"/>
                            { user.theUserRoles[0] == "AD" || user.theUserRoles[0] == "DAD" ?
                                <Tab label="Company" value="2"/>
                                :
                                <p></p>
                            }
                            { user.theUserRoles[0] == "AD" || user.theUserRoles[0] == "DAD" ?
                                <Tab label="Employees" value="3"/>
                                :
                                <p></p>
                            }
                            {user.theUserRoles[0] == "AD" && userCompany[0]?.processExternal == true?
                                <Tab label="Price Options" value="4"/>
                                :
                                <p></p>
                            }
                            {user.theUserRoles[0] == "AD" || user.theUserRoles[0] == "DAD" ?
                                <Tab label="Licenses" value="5"/>
                                :
                                <p></p>
                            }
                            <Tab label="LOG OUT" style={{color:'red'}} value="7" href="/logout" onClick={() => dispatch({type:'USER_LOGOUT'})}/>
                        </TabList>
                    </Box>
                    {/* {user.theUserRoles[0] == "AD" || user.theUserRoles[0] == "DAD" ?
                        <TabPanel value="1">{<Payment/>}</TabPanel>
                        :
                        <p></p>
                    } */}
                    <TabPanel value="1">{<UserInfo/>}</TabPanel>
                    {user.theUserRoles[0] == "AD" || user.theUserRoles[0] == "DAD" ?
                        <TabPanel value="2">{<BusinessInfo/>}</TabPanel>
                        :
                        <p></p>
                    }
                    {user.theUserRoles[0] == "AD" || user.theUserRoles[0] == "DAD" ?
                        <TabPanel value="3">{<UserPage/>}</TabPanel>
                        :
                        <p></p>
                    }
                    {user.theUserRoles[0] == "AD" && userCompany[0]?.processExternal == true?
                        <TabPanel value="4">{<PriceOptionsTab/>}</TabPanel>
                        :
                        <p></p>
                    }
                    {user.theUserRoles[0] == "AD" || user.theUserRoles[0] == "DAD" ?
                        <TabPanel value="5">{<LicenseTab/>}</TabPanel>
                        :
                        <p></p>
                    }
                </TabContext>
            </Box>
        </Page>
    )
};

export default ManageAccount;