import { combineReducers } from 'redux';

const processorVersion = (state = '', action) => {
    switch (action.type) {
        case "SET_FILE_VERSION":
            state = action.payload;
            return state;
        default:
            return state;
    }
}

const isLoading = (state = false, action) => {
    switch (action.type) {
        case "FILE_LOADING":
            state = action.payload;
            return state;
        default:
            return state;
    }
}

const file = (state = {file: [], error: 0, info: [], summary: []}, action) => {
    switch (action.type) {
        case "VALIDATION_FILE":
            state.file = action.payload
            return state;
        case "SUMMARY_FILE":
            state.summary = action.payload
            return state;
        case "VALIDATION_INFO":
            state.info = action.payload
            return state;
        case "VALIDATION_ERROR":
            state.error = action.payload
            return state;
        case "VALIDATION_RESET":
            state = {file: [], error: 0, info: [], summary: []};
            return state;
        default:
            return state;
    }
}
const PDFfile = (state = {file: ''}, action) => {
    switch (action.type) {
        case "PDF_FILE":
            state.file = action.payload
            return state;
        case "PDF_RESET":
            state = {file: []};
            return state;
        default:
            return state;
    }
}

const allFiles = (state = [], action) => {
    switch (action.type) {
        case "SET_COMPANY_FILES":
            state = action.payload;
            return state;
        default:
            return state;
    }
}

const allPaidFiles = (state = [], action) => {
    switch (action.type) {
        case "SET_COMPANY_PAID_FILES":
            state = action.payload;
            return state;
        default:
            return state;
    }
}

const fileLoading = (state = false, action) => {
    switch (action.type) {
        case "REPORT_LOADING":
            state = true;
            return state;
        case "REPORT_DONE":
            state = false;
            return state;
        default:
            return state;
    }
}

const allCustomerFiles = (state = [], action) => {
    switch (action.type) {
        case "SET_CUSTOMER_FILES":
            state = action.payload;
            return state;
        default:
            return state;
    }
}

const filePoints = (state = [], action) => {
    switch (action.type){
        case "SET_FILE_POINTS":
            state = action.payload;
            return state;
        default:
            return state;
    }
}

const vids = (state = [], action) => {
    switch (action.type) {
        case "SET_VIDS":
            state = action.payload;
            return state;
        default:
            return state;
    }
}

export default combineReducers({
    isLoading,
    file,
    allFiles,
    allPaidFiles,
    fileLoading,
    PDFfile,
    allCustomerFiles,
    filePoints,
    processorVersion,
    vids,
});