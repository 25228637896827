import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    AppBar,
    Box,
    Hidden,
    IconButton,
    Toolbar,
    colors,
    Badge
} from '@mui/material';
import { Notifications } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../../src/components/Logo';
import { useSelector } from 'react-redux';

const TopBar = ({ onMobileNavOpen }) => {
    const [notifications] = useState([{}, {}]);
    const auth = useSelector((state) => state.UserReducer.isAuthenticated);

    function notif() {
        if (auth) {
            return (
                <IconButton color="inherit" size="large">
                    <Badge
                        badgeContent={notifications.length}
                        color="warning"
                        variant="standard"
                    >
                        <Notifications color="white" />
                    </Badge>
                </IconButton>
            )
        }
    }

    return (
        <AppBar
            elevation={0}
            sx={{ backgroundColor: colors.grey[900] }}
        >
            <Toolbar>
                <RouterLink to="/" style={{display: 'flex'}}>
                    <Logo />
                </RouterLink>
                <Box flexGrow={1} />
                {/* {notif()} */}
                <Hidden lgUp>
                    <IconButton sx={{}} color="inherit" onClick={onMobileNavOpen} size="large">
                        <MenuIcon />
                    </IconButton>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
};

TopBar.propTypes = {
    className: PropTypes.string,
    onMobileNavOpen: PropTypes.func
};

export default TopBar;