import React from "react";
import {
    Container,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Button,
    Tabs,
    Tab,
    Box
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CurrencyFormat from 'react-currency-format';
import './EstimateView.css';

function EstimateView({ item, handleInvoiceClose }) {
    const userData = useSelector((state) => state.UserReducer.user);
    const company = useSelector((state) => state.CompanyReducer.userCompanies[0]);
    const dispatch = useDispatch();
    console.log("ITEM MADE IT HERE", item)
    console.log("USER", userData)
    let data = [];
    let invoiceTotal = 0;
    let taxTotal = 0;
    let finalTotal = 0;
    let pointTypeTotal = 0;
    if (item?.invoiceValues != undefined) {
        data = JSON?.parse(item?.invoiceValues)
        console.log('Data', data);

        pointTypeTotal = data.pointTypeTotal;

        for (let i = 0; i < data.items.length; i++) {
            invoiceTotal += data.items[i].total;
        }
        console.log('total', invoiceTotal)
        for (let i = 0; i < data.taxes.length; i++) {
            taxTotal += Number(data.taxes[i].total);
        }
        finalTotal = taxTotal + invoiceTotal
    };

    let pointData = [];
    if (item?.pointList != undefined) {
        pointData = JSON?.parse(item?.pointList)
    };

    let rejectInvoice = {
        id: item.estimateId,
        isInvoiceAccepted: 3
    };
    let reject = 1;
    let acceptInvoice = {
        id: item.estimateId,
        isInvoiceAccepted: 2
    };
    let accept = 2;

    function rejectEstimate() {
        dispatch({ type: "UPDATE_INVOICE_STATUS", payload: rejectInvoice, handleInvoiceClose });
    };
    function acceptEstimate() {
        dispatch({ type: "UPDATE_INVOICE_STATUS", payload: acceptInvoice, handleInvoiceClose });
    };

    //
    //For File name selection in invoice//
    //
    function getLastItem(thePath) {
        if (thePath == null || thePath == "") return "";
        var index = thePath.lastIndexOf('/') + 1;
        return thePath.substring(index);
    };

    const fileReport = useSelector((state) => state.FileReducer.file);
    const [results, setResults] = React.useState(fileReport.file);
    const [value, setValue] = React.useState(0);
    const [summary, setSummary] = React.useState(fileReport.summary);

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`tabpanel-${index}`}
                {...other}
            >
                <Box p={3}>
                    {children}
                </Box>
            </div>
        )
    };
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    // let pointTotal = 0;
    // const [pointTypeTotal, setPointTypeTotal] = React.useState(0);
    // let pointList = JSON.parse(item.data.pointList);

    // function pointCounter(){
    //     for (let i = 0; i < pointList?.length; i++) {
    //         if (pointList[i].TypeEnum == 1 || pointList[i].TypeEnum == 2 || pointList[i].TypeEnum == 3 ){
    //             pointTotal += pointList[i].Quantity;
    //         }
    //     }
    //     setPointTypeTotal(pointTotal);
    //     console.log(pointTotal);
    // }

    React.useEffect(() => {
        setResults(fileReport.file);
        setSummary(fileReport.summary);
    }, [fileReport.file, fileReport.summary]);

    return (
        <Container style={{ backgroundColor: 'lightgray', maxWidth: '100vw', position: 'fixed', height: '100%' }}>
            <Container className='estimatePage' maxWidth="sm" style={{ padding: 20, width: '75vw', display: 'flex', flexDirection: 'column', overflowY: 'scroll', height: 'inherit' }}>
                <Box sx={{ width: '95%' }}>
                    <Tabs value={value} onChange={handleTabChange} centered style={{ borderBottom: '2px solid black', paddingBottom: '10px' }}>
                        <Tab label="Estimate Review" />
                        <Tab label="Validation & Summary" />
                        {/* <Tab label="Summary Sequence"/> */}
                    </Tabs>
                    {/* {errorAlert(file.error)} */}
                    <TabPanel value={value} index={0} style={{}}>

                        <Container>
                            <Typography variant="h4" align="right">
                                Estimate Review
                            </Typography>
                            <p style={{ display: "flex", justifyContent: 'right', width: "100%" }}>
                                <span style={{ marginRight: 50 }}>Date: {data.date}</span>
                                <span># {data?.estimateNumber}</span>
                            </p>
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 1, margin: 2 }}>
                                    <div style={{ flex: 1, margin: 2 }}>
                                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', padding: 10 }}>
                                            <h4 style={{ display: "flex", justifyContent: 'left' }}>From Processor:</h4>
                                            <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>{item.companyContact}</span></span>
                                            <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>{item.companyStreet}</span></span>
                                            <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>{item.companyCity} {item.companyState} {item.companyZip}</span></span>
                                            <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>{item.companyCountry}</span></span>

                                        </div>
                                    </div>
                                    <div style={{ flex: 1, margin: 2 }}>
                                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', padding: 10 }}>

                                            <h4 style={{ display: "flex", justifyContent: 'left' }}>To Customer:</h4>
                                            <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>User: {userData?.firstName} {userData?.lastName}</span></span>
                                            <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>{userData?.email}</span></span>
                                            <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Company: {company.name}</span></span>
                                            <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>(Placeholder) Processor Phone#?</span></span>

                                            <span></span><br />
                                            {company.companyStatus === 0 &&
                                                <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Pending</span></span>
                                            }
                                            {company.companyStatus === 1 &&
                                                <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Approved with Credit</span></span>
                                            }
                                            {company.companyStatus === 2 &&
                                                <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Approved with out Credit</span></span>
                                            }
                                            {company.companyStatus === 3 &&
                                                <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Denied</span></span>
                                            }
                                            <br />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ flex: 1, margin: 2 }}>
                                </div>
                                <div style={{ flex: 1, margin: 2 }}>
                                    <div style={{ border: '1px black solid' }}>
                                        <div>
                                            <p>Plan ID: {data.id}</p>
                                        </div>
                                        <div>
                                            <p>Conversion Details:</p>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <span style={{ display: "flex", justifyContent: "space-between", width: "75%", margin: 5, borderTop: '1px lightgray solid' }}><span>Description:</span><span>Qty:</span></span>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <span style={{ display: "flex", justifyContent: "space-between", width: "75%", margin: 5, borderTop: '1px lightgray solid' }}><span>E+C+U Total</span><span>{pointTypeTotal}</span></span>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <span style={{ display: "flex", justifyContent: "space-between", width: "75%", margin: 5, borderTop: '1px lightgray solid' }}><span></span><span></span></span>
                                            </div>
                                            {pointData?.map((item, i) => (
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    {item.Enabled == true &&
                                                        <span style={{ display: "flex", justifyContent: "space-between", width: "75%", margin: 5, borderTop: '1px lightgray solid' }}><span>{item.Description}</span> <span>{item.Quantity}</span></span>
                                                    }
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <Typography variant="body1">
                                Job Name: {data.name}<br />
                                {/* Purchase includes: {getLastItem(botmarkEstimateObj.paidFileUrl)}, {getLastItem(botmarkEstimateObj.paidTxtUrl)} <br/> */}
                            </Typography>
                            <br />
                            <Table>
                                <TableHead>
                                    <TableRow><TableCell colSpan={7}></TableCell></TableRow>
                                    <TableCell className="estimateTableCellVariant">Item</TableCell>
                                    <TableCell className="estimateTableCellVariant">Description</TableCell>
                                    <TableCell className="estimateTableCellVariant">Qty</TableCell>
                                    <TableCell className="estimateTableCellVariant">U/M</TableCell>
                                    <TableCell className="estimateTableCellVariant">Rate</TableCell>
                                    <TableCell className="estimateTableCellVariant">Amount</TableCell>
                                    <TableCell className="estimateTableCellVariant"></TableCell>
                                </TableHead>
                                <TableBody>
                                    {data.items?.map((item, i) => (
                                        <TableRow>
                                            <TableCell className="estimateTableCellVariant">{item?.itemName}</TableCell>
                                            <TableCell className="estimateTableCellVariant">{item?.description}</TableCell>
                                            <TableCell className="estimateTableCellVariant">{item?.quantity}</TableCell>
                                            <TableCell className="estimateTableCellVariant">{item?.unit}</TableCell>
                                            <TableCell className="estimateTableCellVariant">{item?.rate}</TableCell>
                                            <TableCell className="estimateTableCellVariant"> <CurrencyFormat value={Number(item?.quantity) * item?.rate} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2}></CurrencyFormat></TableCell>
                                            <TableCell className="estimateTableCellVariant"></TableCell>
                                        </TableRow>
                                    ))}
                                    {/* <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell>{userCompany[0].name} Invoice Total</TableCell>
                                        <TableCell><CurrencyFormat value={invoiceTotal} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} /></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow> */}
                                    {/* {data?.taxes?.map((item, i) => (
                                        <TableRow>
                                            <TableCell>{item?.itemName}</TableCell>
                                            <TableCell>{item?.description}</TableCell>
                                            <TableCell></TableCell>
                                            <TableCell>{item?.unit}</TableCell>
                                            <TableCell>{item?.rate}</TableCell>
                                            <TableCell><CurrencyFormat value={item?.total} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} /></TableCell>
                                        </TableRow>
                                    ))} */}
                                    <TableRow>
                                        <TableCell className="estimateTableCellVariant"></TableCell>
                                        <TableCell className="estimateTableCellVariant"></TableCell>
                                        <TableCell className="estimateTableCellVariant"></TableCell>
                                        <TableCell className="estimateTableCellVariant"></TableCell>
                                        <TableCell className="estimateTableCellVariant"><b>Total:</b></TableCell>
                                        <TableCell className="estimateTableCellVariant"><CurrencyFormat value={finalTotal} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} /></TableCell>
                                        <TableCell className="estimateTableCellVariant"></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <br />
                        </Container>
                        <span>Accepting Estimate: Agree to payment amount and generate Estimate. Plans will become avalible after Estimate has been Accepted.</span>
                        <br />
                        <Button onClick={() => rejectEstimate()}>Reject</Button>
                        <Button onClick={() => acceptEstimate()}>Accept</Button>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Box m={2} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', height: '100%', minHeight: '300px' }}>
                            <div style={{ flex: 2, margin: 20 }}>
                                <h4 style={{ textAlign: 'left' }}>Validation:</h4>
                                <pre style={{ textAlign: 'left' }}>
                                    {results}
                                </pre>
                            </div>
                            <div style={{ flex: 1, margin: 20 }}>
                                <h4 style={{ textAlign: 'left' }}>Summary:</h4>
                                <pre style={{ textAlign: 'left' }}>
                                    {summary}
                                </pre>
                            </div>
                        </Box>
                    </TabPanel>
                </Box>
            </Container>
        </Container>
    )
}

export default EstimateView;