import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Button,
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    Slide,
    Backdrop,
    CircularProgress,
    Tabs,
    Tab,
    Alert,
    Modal
} from '@mui/material';
import swal from 'sweetalert';
import ValidationFileName from './ValidationFileName';
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            {...other}
        >
            <Box p={3}>
                {children}
            </Box>
        </div>
    )
};

export default function ReviewPage(props) {
    /// validation page for files ///
    const userdata = useSelector((state)=> state.UserReducer.user);
    const file = useSelector((state) => state.FileReducer.file);
    const loading = useSelector((state) => state.FileReducer.fileLoading);
    const companyId = useSelector((state) => state.CompanyReducer.userCompanies[0]);
    const [results, setResults] = React.useState(file.file);
    const [value, setValue] = React.useState(0);
    const [summary, setSummary] = React.useState(file.summary);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [clientName, setClientName] =  React.useState('');
    const [jobName, setJobName] =  React.useState('');
    const [areaName, setAreaName] =  React.useState('');
    const [floorName, setFloorName] =  React.useState('');
    const [planName, setPlanName] =  React.useState('');
    const [fileName, setFileName] = React.useState(file.info.name);

    const pdf = useSelector((state)=> state.FileReducer.PDFfile.file);
    const [pdfUrl, setPdfUrl] = React.useState(`${pdf}`);
    const [pdfStatus, setPdfStatus] = React.useState();

    let user = {
        Id: userdata.id,
        hasAcceptedUploadPolicy: userdata.hasAcceptedUploadPolicy,
        hasAcceptedDownloadPolicy: userdata.hasAcceptedDownloadPolicy,
        hasConfirmedEmail: userdata.hasConfirmedEmail,
        hasReadPrivacyPolicy: userdata.hasReadPrivacyPolicy,
    }

    function changePDFStatus(event){
        setPdfStatus(!pdfStatus);
        if (event === true) {
            user = {
                Id: userdata.id,
                hasAcceptedUploadPolicy: userdata.hasAcceptedUploadPolicy,
                hasAcceptedDownloadPolicy: 1,
                hasConfirmedEmail: userdata.hasConfirmedEmail,
                hasReadPrivacyPolicy: userdata.hasReadPrivacyPolicy,
            }
            dispatch({ type: "UPDATE_AGREEMENT", payload: user});
        }
        else if (event === false) {
            user = {
                Id: userdata.id,
                hasAcceptedUploadPolicy: userdata.hasAcceptedUploadPolicy,
                hasAcceptedDownloadPolicy: 0,
                hasConfirmedEmail: userdata.hasConfirmedEmail,
                hasReadPrivacyPolicy: userdata.hasReadPrivacyPolicy,
            }
            dispatch({ type: "UPDATE_AGREEMENT", payload: user});
        }
    };
    
    React.useEffect(() => {
        setResults(file.file);
        setSummary(file.summary);
        setFileName(file.info.name);
        dispatch({ type: 'GET_PDF_FILE', payload: 2, setPdfUrl });
        if (userdata.hasAcceptedDownloadPolicy === 0){
            setPdfStatus(false)
        }
        if (userdata.hasAcceptedDownloadPolicy === 1){
            setPdfStatus(true)
        }
    }, [file.file]);
    
    const handleClose = () => {
        dispatch({ type: "VALIDATION_RESET" });
        props.change(false);
        setClientName('');
        setJobName('');
        setAreaName('');
        setFloorName('');
        setPlanName('');
        setFileName(file.info.name);
        setResults();
        setValue(0);
        setSummary();
        setOpen(false);
    };

    const [open, setOpen] = React.useState(false);
    const [Obj, setObj] = React.useState({});
    function handleOpenModal(x){
        setOpen(true)
        setObj(x);
        // dispatch({ type: 'GET_VALIDATION_FILE', payload: x.id })
    };
    function handleCloseModal(){
        setOpen(false);
        setObj({});
    };
    
    const handleAccept = () => {
        // console.log(file.error);
        // console.log(file.info.name);
        if (file.error != 0){
            swal({
                title: "CRITICAL ERROR WAVER",
                text: `You are about to accept a file containing a critcal error. By doing so you are accepting the responsiblilty entailed.`,
                icon: "warning",
                buttons: true,
                dangerMode: false,
            })
            .then((willUpdate) => {
                if (willUpdate) {
                    if (file.info.name?.includes(defaultFile)){
                        swal({
                            text: 'Default File Name found, please enter a unique file name.',
                            icon: "error"
                        });
                        return;
                    }
                    else{
                        dispatch({ type: "ACCEPT_FILE", payload:{id: file.info.id, company: companyId.id, acceptance: 1 }});
                        props.change(false);
                        handleCloseModal();
                        navigate("/");
                    }
                }
            }    
        )}
        else {
            if (file.info.name?.includes(defaultFile)){
                swal({
                    text: 'Default File Name found, please enter a unique file name.',
                    icon: "error"
                });
                return;
            }
            else{
                dispatch({ type: "ACCEPT_FILE", payload: {id: file.info.id, company: companyId.id, acceptance: 1 }});
                props.change(false);
                handleCloseModal();
                navigate("/");
            }
        }
    };

    const handleAcceptHelp = () => {
        // console.log(file.error);
        // console.log(file.info.name);
            if (file.info.name?.includes(defaultFile)){
                swal({
                    text: 'Default File Name found, please enter a unique file name.',
                    icon: "error"
                });
                return;
            }
            else{
                dispatch({ type: "ACCEPT_FILE", payload: {id: file.info.id, company: companyId.id, acceptance: 0 }});
                props.change(false);
                handleCloseModal();
                navigate("/");
            }
    };

    function handleExitDelete(){
        swal({
            title: "CRITICAL ERROR DELETE",
            text: `You are about to DELETE a file containing a critcal error. By doing so you are losing access to this file.`,
            icon: "warning",
            buttons: true,
            dangerMode: false,
        })
        .then((willUpdate) => {
            if (willUpdate) {
                    dispatch({ type: "ACCEPT_FILE", payload:{id: file.info.id, company: companyId.id, acceptance: 2 }});
                    props.change(false);
                    handleCloseModal();
            }
        })
    };

    function handleExit(){
        handleCloseModal();
        handleClose();
    }
    
    const handleDownload = () => {
        dispatch({ type: "DOWNLOAD_VALIDATION", payload: file.info, company: companyId.id });
    };
    
    const handlePay = () => {
        if (file.info.name?.includes(defaultFile)){
            swal({
                text: 'Default File Name found, please enter a unique file name.',
                icon: "error"
            });
            return;
        }
        else{
            dispatch({ type: "ACCEPT_FILE", payload: file.info.id, company: companyId.id });
            props.change(false);
            props.pay(true);
        }
    };
    
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    
    const handleErrors = (error) => {
        if ( error === 0 || error === 1 ) {
            return false;
        }
        else {
            return true;
        }
    };
    const errorAlert = (error) => {
        if ( error === 0 || error === 1 ) {
            return "";
        }
        else {
            return <Alert sx={{ display: 'flex', justifyContent: 'center', textAlign:'center', width:'100vw' }} severity='error'> 
                CRITICAL ERROR: File contains a crital error.
                <br/>
                Accepting this file with the error is allowed but with the provision that a user accepts responsiblity for the error.
                <br/>
                Alternativly not accepting the file and downloading the report is the other option. Doing this will then also remove the file from the file list.

            </Alert>
        }
    };

    const item = {
        id: file.info.id,
        Name: fileName,
    };

    const defaultFile = "NewPlan";
    const actualFile = fileName;

    /// Set File Name
    function submitName(){
        if (actualFile?.includes(defaultFile)){
            swal({
                text: 'Default File Name found, please enter a unique file name.',
                icon: "error"
            });
            return;
        } else if(jobName === ""){
            swal({
                text: 'Job Name required, please enter a job name.',
                icon: "error"
            });
            return;
        } else if (floorName === ""){
            swal({
                text: 'Floor level required, please enter a floor level.',
                icon: "error"
            });
            return;
        }
        else if ((jobName != '')&&(floorName != '')){        
            swal({
                    title: "YOU ARE ABOUT TO UPDATE FILE NAME",
                    text: `New File Name: ${fileName}?`,
                    icon: "warning",
                    buttons: true,
                    dangerMode: false,
                })
            .then((willUpdate) => {
                if (willUpdate) {
                    dispatch({type: "UPDATE_FILE_NAME", payload: item, company: companyId.id });
                    setClientName('');
                    setJobName('');
                    setAreaName('');
                    setFloorName('');
                    setPlanName('');
                    setFileName(file.info.name);
            } else {
                swal({
                    title: `File Name not updated.`
                });
            }
        })}
    };

    function resetInputs(){
        setClientName('');
        setJobName('');
        setAreaName('');
        setFloorName('');
        setPlanName('');
        setFileName(file.info.name);
    };
    
    const handleUpload = () => { 
        submitName();
    };
    
  return (
    <div>
        <Dialog
            fullScreen
            open={props.status}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            { userdata.hasAcceptedDownloadPolicy === 0 ?
            <div>
                <AppBar sx={{ position: 'relative', backgroundColor: 'black' }}>
                    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div style={{marginBottom: 5+'%'}}>
                    <h3>Downloader Agreement</h3>
                    <p>Read and accept the below User Agreement to download plans.</p>
                    <iframe style={{width: "100%", height: '90vh'}} src={pdfUrl}></iframe>
                    <Form style={{margin: '5%'}}>
                        <Label check>
                            <Input type="switch" role="switch" checked={pdfStatus} onChange={(event) => changePDFStatus(event.target.checked)}/>{' '}
                            Accept Download Policy
                        </Label>
                    </Form>
                </div>
                </div>
            :
            <div>
            <AppBar sx={{ position: 'relative', backgroundColor: 'black' }}>
                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Box>
                        <Button autoFocus color="inherit" onClick={handleDownload} variant="outlined">
                            Download
                        </Button>    
                        { file.error != 0 ?            
                            <Button sx={{ marginLeft: '10px' }} autoFocus color="inherit"
                                // disabled={handleErrors(file.error)}
                                onClick={()=>handleOpenModal()} variant="outlined">
                                Options to Accept with Critical Errors
                            </Button>
                            :
                            <Button sx={{ marginLeft: '10px' }} autoFocus color="inherit"
                                // disabled={handleErrors(file.error)}
                                onClick={()=>handleAccept()} variant="outlined">
                                Accept
                            </Button>
                        }
                    </Box>
                </Toolbar>
            </AppBar>
            { loading ?
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
            >
                <CircularProgress size={100} color="warning" />
            </Backdrop>
            :
            <Box sx={{ width: '95%' }}>
                <Tabs value={value} onChange={handleChange} centered >
                    <Tab label="File Name"/>
                    <Tab label="Validation & Summary"/>
                    {/* <Tab label="Validation Report"/> */}
                    {/* <Tab label="Summary Sequence"/> */}
                </Tabs>
                {errorAlert(file.error)}
                <TabPanel value={value} index={0}>
                    <ValidationFileName props={props} companyId={companyId} setClientName={setClientName} setJobName={setJobName} setAreaName={setAreaName} setFloorName={setFloorName} setPlanName={setPlanName} handleUpload={handleUpload} setFileName={setFileName}
                            clientName={clientName} jobName={jobName} areaName={areaName} floorName={floorName} planName={planName} file={file} fileName={fileName}/>

                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Box m={2} sx={{  display: 'flex', flexDirection:'row', justifyContent: 'center',height: '100%', minHeight: '300px'}}>
                        <div style={{flex:2, margin: 20}}>
                            <h4 style={{textAlign:'left'}}>Validation:</h4>
                            <pre style={{textAlign:'left'}}>
                                {results}
                            </pre>
                        </div>
                        <div style={{flex:1, margin:20}}>
                            <h4 style={{textAlign:'left'}}>Summary:</h4>
                                <pre style={{textAlign:'left'}}>
                                    {summary}
                                </pre>
                        </div>
                    </Box> 
                </TabPanel>
                {/* <TabPanel value={value} index={2}>
                </TabPanel> */}
            </Box>
            }
            </div>
        }
        </Dialog>
        <Modal
            open={open}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            // className="estimateModal"
            style={{height:'85%', width: '85%', border:'solid red 5px', borderRadius:'50px', boxShadow: '10px 10px 10px 10px rgba(168,168,168,0.45)', margin:'auto', textAlign:'center', backgroundColor:'white' }}
            hideBackdrop={true}
        >
            <div style={{height:'100%'}}>
                <div>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleCloseModal}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <h3>This Plan has Unresolved Critical Errors which may cause Detailing Errors.</h3>
                    <p>You are being advised of the options and responsibility of resolving detailing errors that can exist in this plan, such that corrective action is in place for it. </p>
                    <p>If accepted, this plan and processor identity will forwarded to Botmark Inc. for R & D, Botmark Inc may contact you about the critical error. </p>
                    <h4>Please select from options below:</h4>
                </div>
                <div style={{marginTop:'5%'}}>
                    <p style={{marginTop:'3%'}}>1. <Button onClick={()=>handleAccept()} variant="outlined" color='error'>YES</Button> I Accept and waive Botmark Inc. from liability and are able to accept <span style={{color:'red'}}>that my plan has errors and may detail incorrectly.</span></p>
                    <p style={{marginTop:'3%'}}>2. <Button onClick={()=>handleAcceptHelp()} variant="outlined" color='warning'>NO</Button> I am unable to accept liability. Please contact Botmark Inc. ASAP for assistance.</p>
                    {/* <p>3.  Exit and delete to rework the process </p> */}
                    <p style={{marginTop:'3%'}}>3. <Button onClick={()=>handleExitDelete()} variant="outlined" color='warning'>EXIT</Button> and Delete the Plan to rework from Upload. </p>
                    <pre style={{textAlign:'left', marginLeft:'10%', marginRight:'10%', marginTop:'2%', padding:'1%', border:'solid darkred 2px', height:'32vh', overflowY:'scroll'}}>
                        {results}
                    </pre>
                </div>
            </div>
        </Modal>
    </div>
  );
}