import React from 'react';

import {
    Box,
    Typography,
} from "@mui/material";

function ReplyDisplay({item, style}){
  /// displays a popout containing a reply from a processor
    return(
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Reply from {item.companyContact}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 4 }}>
            { item.reply === '' ?
                'N/A'
            :
                `${item.reply}`
            }
          </Typography>
        </Box>
    )
}

export default ReplyDisplay;