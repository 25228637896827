import React from 'react';

const Logo = (props) => {
  return (
    <img
        alt="Logo"
        height="40%"
        width="40%"
        src="/botmarklogo.png"
        {...props}
    />
  );
};

export default Logo;
