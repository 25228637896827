import React from "react";
import {
    Card,
    Button,
    Container,
    TextField,
    Typography,
    Box,
    IconButton,
    Autocomplete
} from "@mui/material";
import * as Yup from 'yup';
import { Formik } from 'formik';
import { XSquare } from "react-feather";
import Countries from "../../mixins/Countries";
import States from "../../mixins/States";
import { useDispatch, useSelector } from "react-redux";

const CreateCompany = React.forwardRef((props, ref) => {
    const roles = useSelector((state) => state.CompanyReducer.allRoles);
    const { close } = props;
    const dispatch = useDispatch();
    const user = useSelector((state) => state.UserReducer.user)

    let adminRoles = [];
    roles.forEach(findAdminRoles);
    function findAdminRoles(item){
        if(item.name == "Admin" || item.name == "Downloader Admin"){
            adminRoles.push(item)
        }
    }
    console.log(adminRoles)

    return (
        <Box>
            <Box p={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography variant="h4">
                    Create new company
                </Typography>
                {/* <IconButton color="primary" onClick={close}>
                    <XSquare/>
                </IconButton> */}
            </Box>
            <Container maxWidth="sm" sx={{ p: 2 }}>
                <Formik
                    initialValues={{
                        Name: '',
                        Street: '',
                        City: '',
                        State: '',
                        Zip: '',
                        Country: '',
                        Email: '',
                        PhoneNumber: ''
                    }}
                    validationSchema={
                        Yup.object().shape({
                            Name: Yup.string().max(255).required('Company name required'),
                            Street: Yup.string().max(255).required('Street required'),
                            City: Yup.string().max(255).required('City required'),
                            State: Yup.string().max(255).required('State required'),
                            Zip: Yup.string().max(10).required('Zip code required'),
                            Country: Yup.string().max(255).required('Country required'),
                            Email: Yup.string().max(225).required('Email Required'),
                            PhoneNumber: Yup.string().max(225).required('Phone Required')
                        })
                    }
                    onSubmit={(values) => {
                        console.log("HERE", values)
                        console.log(user.id)
                        console.log(values.Role)
                        
                        dispatch({type: "CREATE_COMPANY", payload: {company: values, userId: user.id, roleId: values.Role}});
                        close();
                    }}
                >
                    {({
                        errors,
                        setFieldValue,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <TextField
                                error={Boolean(touched.Name && errors.Name)}
                                fullWidth
                                helperText={touched.Name && errors.Name}
                                label="Name"
                                margin="normal"
                                name="Name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.Name}
                                variant="outlined"
                            />
                            <TextField
                                error={Boolean(touched.Street && errors.Street)}
                                fullWidth
                                helperText={touched.Street && errors.Street}
                                label="Street"
                                margin="normal"
                                name="Street"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.Street}
                                variant="outlined"
                            />
                            <TextField 
                                error={Boolean(touched.City && errors.City)}
                                fullWidth
                                helperText={touched.City && errors.City}
                                label="City"
                                margin="normal"
                                name="City"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.City}
                                variant="outlined"
                            />
                            <Autocomplete
                                fullWidth
                                options={States}
                                autoHighlight
                                name="State"
                                onChange={(e, value) => setFieldValue("State", value?.name || "")}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                    <TextField 
                                        {...params} 
                                        error={Boolean(touched.State && errors.State)}
                                        helperText={touched.State && errors.State}
                                        label="State"
                                        margin="normal"
                                        onBlur={handleBlur}
                                        value={values.State}
                                    />
                                )}
                            />
                            <TextField
                                error={Boolean(touched.Zip && errors.Zip)}
                                fullWidth
                                helperText={touched.Zip && errors.Zip}
                                label="Zip"
                                margin="normal"
                                name="Zip"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                value={values.Zip}
                            />
                            <Autocomplete 
                                fullWidth
                                options={Countries}
                                name="Country"
                                autoHighlight
                                onChange={(e, value) => setFieldValue("Country", value?.name || "")}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        error={Boolean(touched.Country && errors.Country)}
                                        helperText={touched.Country && errors.Country}
                                        label="Country"
                                        margin="normal"
                                        onBlur={handleBlur}
                                        value={values.Country}
                                    />
                                )}
                            />
                            <TextField
                                error={Boolean(touched.Email && errors.Email)}
                                fullWidth
                                helperText={touched.Email && errors.Email}
                                label="Email"
                                margin="normal"
                                name="Email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                value={values.Email}
                            />
                            <TextField
                                error={Boolean(touched.PhoneNumber && errors.PhoneNumber)}
                                fullWidth
                                helperText={touched.PhoneNumber && errors.PhoneNumber}
                                label="Phone"
                                margin="normal"
                                name="PhoneNumber"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                value={values.PhoneNumber}
                            />
                            <Autocomplete
                                fullWidth
                                autoHighlight
                                options={adminRoles}
                                name="Role"
                                onChange={(e, value) => setFieldValue("Role", value?.id || "")}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        error={Boolean(touched.Role && errors.Role)}
                                        helperText={touched.Roles && errors.Roles}
                                        label="Role"
                                        margin="normal"
                                        onBlur={handleBlur}
                                        value={values.Role}
                            />
                            )}
                        />
                            <Box my={2}>
                                <Button
                                    color="primary"
                                    disabled={isSubmitting}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                >
                                    Create
                                </Button>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Container>
        </Box>
    )
});

export default CreateCompany;