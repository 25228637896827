import RulerCreator from './RulerCreator';
import RulerDataGrid from './RulerDataGrid';


function RulerPage(){
    /// Ruler Page
    return(
        <div>
            <RulerCreator/>
            <RulerDataGrid/>
        </div>
    )
};

export default RulerPage;