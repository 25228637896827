import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Button,
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    Slide,
    Typography
} from '@mui/material';
import PaymentSummary from './PaymentSummary';
import AddressView from './AddressView';
import PaymentView from './PaymentView';
import ReviewPayment from './ReviewPayment';
import EstimateView from './EstimateView';

/// currently not used, maybe delete later

const Steps = {
    PaymentSummary: 0,
    EstimateView: 1,
    PaymentView: 2,
    ReviewPayment: 3,
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PaymentPage(props) {
    const [activeStep, setActiveStep] = React.useState(0);
    const fileId = useSelector((state) => state.FileReducer.file.info.id);
    const companyId = useSelector((state) => state.CompanyReducer.userCompanies[0]);
    const dispatch = useDispatch();


    const handleClose = () => {
            props.change(false);
    };
    const handleSubmit = () => {
            dispatch({ type: "BUY_FILE", payload: fileId, company: companyId.id });
            props.change(false);
    }

    const handleNext = () => setActiveStep(activeStep + 1);
    const handleBack = () => setActiveStep(activeStep - 1);

    function getStepContent(stepIndex) {
        switch (stepIndex) {
            case Steps.PaymentSummary:
                return <PaymentSummary/>
            case Steps.EstimateView:
                return <EstimateView/>
            case Steps.PaymentView:
                return <PaymentView/>
            case Steps.ReviewPayment:
                return <ReviewPayment/>
            default:
                break;
        }
    }
 
    return (
        <div>
            <Dialog
                fullScreen
                open={props.status}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative', backgroundColor: 'black' }}>
                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Button sx={{ ml: 3 }} color="inherit" disabled={activeStep === 0} onClick={handleBack} variant="outlined">
                            Back
                        </Button>
                    </Box>
                    {/* <Box>
                        { activeStep === Steps.ReviewPayment ?
                        <Button color="inherit" onClick={handleSubmit} variant="outlined">
                            Pay Now
                        </Button>
                        :
                        <Button color="inherit" onClick={handleNext} variant="outlined">
                            Next
                        </Button> }
                    </Box> */}
                </Toolbar>
                </AppBar>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {getStepContent(activeStep)}
                </Box>
            </Dialog>
        </div>
    );
}