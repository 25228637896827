import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    Button,
    ListItem,
    Typography
} from '@mui/material';
import { styled } from '@mui/system';
import theme from '../../theme';

const Item = styled(ListItem)({
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
}, `&.Mui-selected {
  background-color: rgba(249,137,42,0.20)
}`)

const MyButton = styled(Button)({
    color: '#f9492a',
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    width: '100%'
})

const NavItem = ({
    href,
    icon: Icon,
    title,
}) => {
  return (
    <Item
        disableGutters
        selected={href === window.location.pathname}
    >
        <MyButton
            component={RouterLink}
            to={href}
            startIcon={ <Icon style={{ marginRight: theme.spacing(1), color: 'rgb(84, 110, 122)' }}/> }
        >
            <Typography sx={{ marginRight: theme.spacing(1), color: 'rgb(84, 110, 122)' }}>
                {title}
            </Typography>
        </MyButton>
    </Item>
  );
};

NavItem.propTypes = {
    className: PropTypes.string,
    href: PropTypes.string,
    icon: PropTypes.elementType,
    title: PropTypes.string
};

export default NavItem;
