import React from "react";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarExport
} from "@mui/x-data-grid";
import {
    Box,
    Hidden,
    Chip,
    Typography,
    Card
} from "@mui/material";
import {
    CheckCircle,
    Loader
} from 'react-feather'

function Color(type) {
    switch (type) {
        case 'complete':
            return <Chip icon={<CheckCircle/>} variant="contained" color='success' label={type} />
        case 'pending':
            return <Chip icon={<Loader/>} variant="contained" color='info' label={type} />
        default:
            break;
    }
}

/// currently not used
const Payment = () => {
    const columns = [
        { field: 'projectName', headerName: 'Project Name', flex: 1, headerClassName: 'header' },
        { field: 'startDate', headerName: 'Start Date', flex: 1, headerClassName: 'header' },
        { field: 'endDate', headerName: 'End Date', flex: 1, headerClassName: 'header' },
        { field: 'status', headerName: 'Status', flex: 1, headerClassName: 'header', renderCell: (params) => (
            Color(params.row.status)
        ) }
    ];
    const rows = [
        // {id: Math.random(), projectName: 'Project 1', startDate: '2/10/22', endDate: '3/10/22', status: 'complete'},
        // {id: Math.random(), projectName: 'Project 2', startDate: '3/10/22', endDate: '4/10/22', status: 'pending'},
        // {id: Math.random(), projectName: 'Project 3', startDate: '4/10/22', endDate: '5/10/22', status: 'complete'},
        // {id: Math.random(), projectName: 'Project 4', startDate: '2/10/22', endDate: '3/10/22', status: 'complete'},
        // {id: Math.random(), projectName: 'Project 5', startDate: '3/10/22', endDate: '4/10/22', status: 'pending'},
        // {id: Math.random(), projectName: 'Project 6', startDate: '4/10/22', endDate: '5/10/22', status: 'complete'},
    ];

    function CustomToolBar() {
        return (
            <GridToolbarContainer>
                <Hidden smDown>
                    <GridToolbarColumnsButton/>
                    <GridToolbarFilterButton/>
                    <GridToolbarDensitySelector/>
                    <GridToolbarExport/>
                </Hidden>
            </GridToolbarContainer>
        )
    };

    return (
        <Box
            p={3}
            sx={{ '& .header': { backgroundColor: '#e2e2e2' } }}
        >
            <DataGrid
                sx={{ height: '70vh' }}
                columns={columns}
                rows={rows}
                components={{
                    Toolbar: CustomToolBar
                }}
            />
        </Box>
    )
};

export default Payment;