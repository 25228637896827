import { combineReducers } from 'redux';

const isLoading = (state = false, action) => {
    switch (action.type) {
        case "USER_LOADING":
            state = action.payload;
            return state;
        default:
            return state;
    }
};

const isDataLoading = (state = false, action) => {
    switch (action.type) {
        case "SET_IS_LOADING":
            state = action.payload;
            return state;
        default:
            return state;
    }
};

const isAuthenticated = (state = false, action) => {
    switch (action.type) {
        case 'IS_AUTHENTICATED':
            state = action.payload;
            return state;
        default:
            return state;
    }
};

const token = (state = '', action) => {
    switch (action.type) {
        case "USER_TOKEN":
            state = action.payload;
            return state;
        default:
            return state;
    }
}

const business = (state = [], action) => {
    switch (action.type) {
        case "SET_BUSSINESS":
            state = action.payload
            return state;   
        default:
            return state;
    }
};

const user = (state = [], action) => {
    switch (action.type) {
        case "SET_USER":
            state = action.payload
            return state;
        default:
            return state;
    }
}

const allUserMessages = (state = [], action) => {
    switch (action.type) {
        case "USER_MESSAGES":
            state = action.payload;
            return state;
        default:
            return state;
    }
}

const allUserInvoices = (state = [], action) => {
    switch (action.type) {
        case "USER_INVOICES":
            state = action.payload;
            return state;
        default:
            return state;
    }
}

const customerSelection = (state = '00000000-0000-0000-0000-000000000000', action) => {
    switch (action.type) {
        case "CUSTOMER_SELECTION":
            state = action.payload;
            return state;
        default:
            return state;
    }
}

export default combineReducers({
    isAuthenticated,
    isLoading,
    business,
    user,
    token,
    allUserMessages,
    allUserInvoices,
    customerSelection,
    isDataLoading,
});