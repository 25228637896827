import React from 'react';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../components/Page';
import {
    Box,
    Typography,
    Button,
    Hidden,
    Modal,
    FormControl,
    InputLabel,
    Select
} from '@mui/material';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarExport
} from '@mui/x-data-grid';
import { Check, PlusSquare } from 'react-feather';
import CreateUser from './CreateUser';
import CheckUser from './CheckUser';
import UserRoleChange from './UserRoleChange';
import "./Users.css";

// TODO: add in user roles
function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton/>
            <Hidden smDown>
                <GridToolbarFilterButton/>
                <GridToolbarDensitySelector/>
            </Hidden>
            <GridToolbarExport/>
        </GridToolbarContainer>
    )
};

const UserPage = () => {
    const [open, setOpen] = React.useState(false);
    const [check, setCheck] = React.useState(false);
    const role = useSelector((state) => state.CompanyReducer.userRole);
    const users = useSelector((state) => state.CompanyReducer.usersInCompany);
    const company = useSelector((state) => state.CompanyReducer.userCompanies[0])
    console.log("Users ", users );
    console.log("Roles ", role );
    const [open1, setOpen1] = React.useState(false);
    const handleOpen1 = () => setOpen1(true);
    const handleClose1 = () => setOpen1(false);
    const dispatch = useDispatch();
    const [rowItem, setRowItem] = useState();
    const [editRowsModel, setEditRowsModel] = React.useState({});

    function editRole(cellValues) { // hadles the double click to bring up modal to edit crop amount
        setRowItem({
            userId: cellValues.row.id,
            userRole: cellValues.row.userRole,
        });
        handleOpen1();
    };

    function deactivateEmployee(cellValues){
        setRowItem({
            userId: cellValues.row.id,
        });
        dispatch({ type: 'DEACTIVATE_EMPLOYEE', payload: {Id: cellValues.row.id, CompanyId: company.id}});
    };

    const columns = [
        { field: 'userName', headerName: 'Username', flex: 1, headerClassName: 'header' },
        { field: 'email', headerName: 'Email', flex: 1, headerClassName: 'header' },
        { field: 'firstName', headerName: 'First Name', flex: .75, headerClassName: 'header' },
        { field: 'lastName', headerName: 'Last Name', flex: .75, headerClassName: 'header' },
        { field: 'userRole', headerName: 'Role', flex: 1, headerClassName: 'header', 
            renderCell: (cellValues)=>(
                <div>
                    <Button variant="contained" color="primary" onClick={() => editRole(cellValues)}>{cellValues.row.userRole}</Button>
                </div>
            )
        },
        { field: 'delete', headerName: 'Deactivate', flex: .75, headerClassName: 'header',
            renderCell: (cellValues)=>{
                if (cellValues.row.deleted === null){
                    return(
                        <Button variant="contained" color="error" onClick={() => deactivateEmployee(cellValues)}>Deactivate</Button>
                    )
                } else {
                    return(
                        <Button variant="contained" color="secondary" onClick={() => deactivateEmployee(cellValues)}>Reactivate</Button>
                    )
                }
            }
        }
    ];

    const handleOpen = () => setOpen(!open);
    const handleCheck = () => setCheck(!check);

    return (
        <Page title="Users">
            <Box p={3} sx={{ '& .header': { backgroundColor: '#e2e2e2' } }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant='h3' textAlign='left' p={2}>
                        Employees
                    </Typography>
                    <Button 
                        variant='text' 
                        // disabled={role.name !== "Admin" || role.name !=="Downloader Admin"}
                        color='warning' 
                        startIcon={<PlusSquare/>} 
                        onClick={handleOpen}
                    >
                        New User
                    </Button>
                    <Modal open={open} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CreateUser close={handleOpen}/>
                    </Modal>
                    <Modal open={check} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CheckUser close={handleCheck}/>
                    </Modal>
                </Box>
                <DataGrid
                    columns={columns}
                    rows={users}
                    sx={{ height: '70vh' }}
                />
                <Modal
                    open={open1}
                    onClose={handleClose1}
                    className="employeeStatusModal"
                >
                <UserRoleChange item={rowItem} handleClose1={handleClose1}/>
                </Modal>
            </Box>
        </Page>
    )
};

export default UserPage;