import React from 'react';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    Select,
    Typography,
} from "@mui/material";
import "./Users.css"

function UserRoleChange({item, style}){
    /// allow for processor to set the status of the customer [pending/approved with credit/approved with out credit/ denied]
    const company = useSelector((state) => state.CompanyReducer.userCompanies[0])
    console.log("COMPANY", company)
    const dispatch = useDispatch();
    const [role, setRole] = React.useState();
    const handleChange = (event) => {
        setRole(Number(event.target.value));
    };

    function updateUserRole(item){
        if (role == 0){
            dispatch({ type: 'EDIT_EMPLOYEE_ROLE', payload: {UserId: item.userId, RoleName: 'Admin', CompanyId: company.id}});
        }
        else if (role == 1){
            dispatch({ type: 'EDIT_EMPLOYEE_ROLE', payload: {UserId: item.userId, RoleName: 'Planner', CompanyId: company.id}});
        }
        else if (role == 2){
            dispatch({ type: 'EDIT_EMPLOYEE_ROLE', payload: {UserId: item.userId, RoleName: 'Download', CompanyId: company.id}});
        }
        else if (role == 3){
            dispatch({ type: 'EDIT_EMPLOYEE_ROLE', payload: {UserId: item.userId, RoleName: 'Downloader Admin', CompanyId: company.id}});
        }
    }

    return(
        <Box sx={style} className="statusBox">
            <Typography>Set Employee Role</Typography>
            <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <FormControl sx={{ m: 2, minWidth: 120 }}>
                    <InputLabel htmlFor="demo-dialog-native">Role</InputLabel>
                    <Select
                        native
                        value={role}
                        onChange={handleChange}
                    >
                        <option aria-label="None" value="" />
                        <option value={0}>Admin</option>
                        <option value={1}>Planner</option>
                        <option value={2}>Downloader</option>
                        <option value={3}>Downloader Admin</option>
                    </Select>
                    <Button onClick={()=>updateUserRole(item)}>Update</Button>
                </FormControl>
            </Box>
        </Box>
    )
}

export default UserRoleChange;