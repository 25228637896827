import React from "react";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarExport
} from "@mui/x-data-grid";
import {
    Box,
    Hidden,
    Button,
    Typography,
    Chip,    
    Modal,
} from "@mui/material";
import {
    CreditCard,
    CheckCircle,
    AlertCircle,
} from 'react-feather';
import { useDispatch, useSelector } from "react-redux";
import ReviewPage from "../billing/Validation";
import EstimateView from "../billing/EstimateView";
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import './plans.css';
import SplitToken from '../../mixins/SplitToken';

const PlanInboxTable = () => {
    /// displays all plans that have been sent for review from a company to the customer
    const [review, setReview] = React.useState(false);
    const [pay, setPay] = React.useState(false);
    const [estimateReview, setEstimateReview] = React.useState(false);
    const customerFiles = useSelector((state) => state.FileReducer.allCustomerFiles.filesDTO);
    const filesCount = useSelector((state) => state.FileReducer.allCustomerFiles.totalFileCount);
    const isLoading = useSelector((state) => state.UserReducer.isDataLoading);

    const allMessages = useSelector((state) => state.UserReducer.allUserMessages);
    const dispatch = useDispatch();
    const user = SplitToken();
    const [edit, setEdit] = React.useState(false);
    const [invoiceModelObj, setInvoiceModelObj] = React.useState({});
    const company = useSelector((state) => state.CompanyReducer.userCompanies[0]);

    const [paginationModel, setPaginationModel] = React.useState({pageSize: 25, page: 0});
    const customerSelect = useSelector((state) => state.UserReducer.customerSelection);

    React.useEffect(() => {
            dispatch({ type: 'GET_CUSTOMER_FILES', payload: {companyId: company?.id, page: paginationModel.page, pageSize: paginationModel.pageSize, customerId: customerSelect }})
    },[paginationModel]);

    const rowCountRef = React.useRef(filesCount || 0);
    
    const rowCount = React.useMemo(() => {
      if (filesCount !== undefined) {
        rowCountRef.current = filesCount;
      }
      return rowCountRef.current;
    }, [filesCount]);

    /// for checking user data to allow user to download if policy is accepted or not
    const userdata = useSelector((state)=> state.UserReducer.user);
    const pdf = useSelector((state)=> state.FileReducer.PDFfile.file);
    const [pdfUrl, setPdfUrl] = React.useState(`${pdf}`);
    const [pdfStatus, setPdfStatus] = React.useState();
    let userD = {
        Id: userdata.id,
        hasAcceptedUploadPolicy: userdata.hasAcceptedUploadPolicy,
        hasAcceptedDownloadPolicy: userdata.hasAcceptedDownloadPolicy,
        hasConfirmedEmail: userdata.hasConfirmedEmail,
        hasReadPrivacyPolicy: userdata.hasReadPrivacyPolicy,
    };

    function showPDF(cellValues){ // hadles the double click to bring up modal to edit crop amount
        handleOpen();
    };

    function showInvoice(x){
        setInvoiceModelObj({
            id: x?.id,
            name: x?.name,
            companyId: x?.companyId,
            isProcessed: x?.isProcessed,
            url: x?.url,
            version: x?.version,
            reportUrl: x?.reportUrl,
            created: x?.created,
            updated: x?.updated,
            deleted: x?.deleted,
            summaryUrl: x?.summaryUrl,
            paidFileUrl: x?.paidFileUrl,
            isAccepted: x?.isAccepted,
            isPaidFor: x?.isPaidFor,
            sequencingErrorCode: x?.sequencingErrorCode,
            paidTextUrl: x?.paidTextUrl,
            pointCount: x?.pointCount,
            estimateId: x?.estimateId,
            dwgUrl: x?.dwgUrl,
            pointList: x?.pointList,

            openInvoice: x?.openInvoice,
            isSent: x?.isSent,
            isInvoiceAccepted: x?.isInvoiceAccepted,
            invoiceCreated: x?.invoiceCreated,
            invoiceCustomerId: x?.invoiceCustomerId,
            invoiceDeleted: x?.invoiceDeleted,
            invoiceDeveloperId: x?.invoiceDeveloperId,
            invoiceId: x?.invoiceId,
            invoiceValues: x?.invoiceValues,
            invoiceJobId: x?.invoiceJobId,
            invoiceUpdated: x?.invoiceUpdated, 

            customerStatus: x?.customerStatus,
            companyContact: x?.companyContact,
            companyStreet: x?.companyStreet,
            companyCity: x?.companyCity,
            companyState: x?.companyState,
            companyZip: x?.companyZip,
            companyCountry: x?.companyCountry
        })
        handleInvoiceOpen();
        dispatch({ type: 'GET_VALIDATION_FILE', payload: x.id });
    };

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [invoiceModal, setInvoiceModal] = React.useState(false);
    const handleInvoiceOpen = () => setInvoiceModal(true);
    const handleInvoiceClose = () => setInvoiceModal(false);

    let userToUpdate = {
        Id: userdata.id,
        hasAcceptedUploadPolicy: userdata.hasAcceptedUploadPolicy,
        hasAcceptedDownloadPolicy: userdata.hasAcceptedDownloadPolicy,
        hasConfirmedEmail: userdata.hasConfirmedEmail,
        hasReadPrivacyPolicy: userdata.hasReadPrivacyPolicy,
    };

    function changePDFStatus(event){
        setPdfStatus(!pdfStatus);
        if (event === true) {
            userToUpdate = {
                Id: userdata.id,
                hasAcceptedUploadPolicy: userdata.hasAcceptedUploadPolicy,
                hasAcceptedDownloadPolicy: 1,
                hasConfirmedEmail: userdata.hasConfirmedEmail,
                hasReadPrivacyPolicy: userdata.hasReadPrivacyPolicy,
            }
            dispatch({ type: "UPDATE_AGREEMENT", payload: userToUpdate});
        }
        else if (event === false) {
            userToUpdate = {
                Id: userdata.id,
                hasAcceptedUploadPolicy: userdata.hasAcceptedUploadPolicy,
                hasAcceptedDownloadPolicy: 0,
                hasConfirmedEmail: userdata.hasConfirmedEmail,
                hasReadPrivacyPolicy: userdata.hasReadPrivacyPolicy,
            }
            dispatch({ type: "UPDATE_AGREEMENT", payload: userToUpdate});
        }
    };

    React.useEffect(() => {
        dispatch({ type: 'GET_PDF_FILE', payload: 2, setPdfUrl });
        dispatch({ type: 'GET_CUSTOMER_FILES', payload: {customerId: user.id, page: paginationModel.page, pageSize: paginationModel.pageSize}});
        dispatch({ type: 'FETCH_USER_MESSAGES', payload: user.id });
        if (userdata.hasAcceptedDownloadPolicy === 0){
            setPdfStatus(false)
        }
        if (userdata.hasAcceptedDownloadPolicy === 1){
            setPdfStatus(true)
        }
    },[]);

    const handleDownload = (file) => {
        dispatch({ type: "DOWNLOAD_PAID", payload: file });
    };

    const columns = [
        { field: 'processorName', headerName: 'Processor', flex: .7, headerClassName: 'header', editable: false},
        { field: 'name', headerName: 'Project Name', flex: 1, headerClassName: 'header', editable: false},
        { field: 'invoiceUpdated', headerName: 'Updated', flex: .5, headerClassName: 'header', renderCell: (params) => {
            let date = new Date(params.row.invoiceUpdated);
            return date.toLocaleString()
        }},
        { field: 'status', headerName: 'Status', flex: .65, headerClassName: 'header', renderCell: (params) => (
            Status(params.row)
        )},
        { field: 'file', headerName: 'File', flex: 1, headerClassName: 'header', renderCell: (params) => (
            File(params.row)
        )}
    ];

    function Status(x) {
        if ( x.isInvoiceAccepted === 1 || x.isInvoiceAccepted === 0 ) {
            return <Chip icon={<CreditCard/>} variant="outlined" color="info" label="ESTIMATE"/>
        }
        else if ( x.isInvoiceAccepted === 3 ) {
            return <Chip icon={<AlertCircle/>} variant="outlined" color="warning" label="ESTIMATE REJECTED"/>
        }
        else {
            return <Chip icon={<CheckCircle/>} variant="contained" color="success" label="COMPLETE"/>
        }
    };
    
    function File(x) {
        if ( x.isPaidFor === 0 && x.openInvoice === true && x.invoiceIsSent === true && x.isInvoiceAccepted === 1 ) {
            return (
                // brings user to estimate page
                <Button variant="contained" color="primary" onClick={() => showInvoice(x)}>
                    Review Estiamte
                </Button>
            )
        }
        else if ( x.isPaidFor === 0 && x.openInvoice === true && x.invoiceIsSent === true && x.isInvoiceAccepted === 3 ) {
            return (
                /// shows rejected invoice
                <Button variant="contained" color="secondary" disabled>
                    Response Pending
                </Button>    
            )
        }
        else if( x.customerStatus === 2){
            return (
                /// shows that customer is locked based on status with company
                <Button variant="contained" color="secondary" disabled>
                    <div>Locked Customer: Contact <b>{x.companyContact}</b></div>
                </Button>
            )
        }
        else if( x.customerStatus === 0){
            return (
                /// shows that customer status hasnt been approved
                <Button variant="contained" color="secondary" disabled>
                    <div>Pending Customer: Contact <b>{x.companyContact}</b></div>
                </Button>
            )
        }
        else if( x.customerStatus === 3){
            return (
                /// shows that customer is locked based on status with company
                <Button variant="contained" color="secondary" disabled>
                    <div>Locked Customer: Contact <b>{x.companyContact}</b></div>
                </Button>
            )
        }
        else if( x.isPaidFor === 0 && x.invoiceIsSent === true && x.isInvoiceAccepted === 2 && x.customerStatus === 1){
            return (
                /// download paid file/ accept download policy
                <div>
                    { userdata.hasAcceptedDownloadPolicy === 0 ?
                        <Button variant="contained" color="primary" onClick={() => showPDF(x)}>
                            Download Policy
                        </Button>
                        :
                        <Button variant="contained" color="success" onClick={() => handleDownload(x)}>
                            Download
                        </Button>
                
                    }
                </div>
            )
        }
    };
    const rows = customerFiles;

    function CustomToolBar() {
        return (
            <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #e0e0e0', p: 1 }}>
                <Hidden smDown>
                    <Box width='40%' textAlign="left">
                        <GridToolbarFilterButton/>
                        <GridToolbarColumnsButton/>
                        <GridToolbarDensitySelector/>
                        <GridToolbarExport/>
                    </Box>
                    <Box width='40%'>
                    </Box>
                </Hidden>
                <Hidden smUp>
                </Hidden>
            </GridToolbarContainer>
        )
    };

    return (
        <Box
            sx={{ '& .header': { backgroundColor: '#e2e2e2' }, height: '70vh' }}
        >
            <Typography textAlign={"left"} variant='h4'>
                Plans Inbox
            </Typography>
            <ReviewPage status={review} change={setReview} pay={setPay}/>
            <DataGrid
                components={{
                    Toolbar: CustomToolBar
                }}
                columns={columns}
                rows={rows}
                loading={isLoading}
                pageSizeOptions={[10, 25, 50, 100]}
                rowCount={rowCount}
                paginationMode="server"
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
            />
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="pdfModal"
            >
                <div style={{marginBottom: 5+'%'}}>
                    <h3>Downloader Agreement</h3>
                    <p>Read and accept the below User Agreement to download plans.</p>
                    <iframe style={{width: "90%", height: '75vh'}} src={pdfUrl}></iframe>
                    <Form style={{margin: '1%'}}>
                        <Label check>
                            <Input className='pdfCheck' type="switch" role="switch" checked={pdfStatus} onChange={(event) => changePDFStatus(event.target.checked)}/>{' '}
                            Accept Upload Policy
                        </Label>
                    </Form>
                    <p className='pdfNotice'>Click margins to close after accepting</p>
                </div>            
            </Modal>
            <Modal className="invoiceModal" open={invoiceModal} onClose={handleInvoiceClose}>
                <div>
                    <Button onClick={() => setInvoiceModal(false)}>Close</Button>
                    <EstimateView item={invoiceModelObj} handleInvoiceClose={handleInvoiceClose}/>
                </div>
            </Modal>
        </Box>
    )
};

export default PlanInboxTable;