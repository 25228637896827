import React from 'react';
import { useEffect, useState } from "react";
import {
    DataGrid,
} from "@mui/x-data-grid";
import {
    Box,
    Button,
    Modal,
    Chip,  
} from "@mui/material";
import {
    CheckCircle,
    Loader,
    AlertCircle,
    XCircle
} from 'react-feather';
import MessageDisplay from '../customers/MessageDisplay';
import ReplyDisplay from './ReplyDisplay';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function MessageDataGrid({messages, i}){
    console.log("Messages", messages)
    const [rowItem, setRowItem] = useState();
    const [editRowsModel, setEditRowsModel] = React.useState({});

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [open1, setOpen1] = React.useState(false);
    const handleOpen1 = () => setOpen1(true);
    const handleClose1 = () => setOpen1(false);

    const [open2, setOpen2] = React.useState(false);
    const handleOpen2 = () => setOpen2(true);
    const handleClose2 = () => setOpen2(false);
    
    /// shows the message the user previously sent in a modal
    function showMessage(cellValues) {
        setRowItem({
            id: cellValues.row.id,
            companyId: cellValues.row.companyId,
            created: cellValues.row.created,
            deleted: cellValues.row.deleted,
            message: cellValues.row.message,
            requestStatus: cellValues.row.requestStatus,
            userId: cellValues.row.userId,
            userEmail: cellValues.row.userEmail,
            userFirstName: cellValues.row.userFirstName,
            userLastName: cellValues.row.userLastName,
            reply: cellValues.row.reply
        })
        handleOpen();
    };
    
    /// Can use this function if editing message becomes a wanted feature
    // function editStatus(cellValues) {
    //     setRowItem({
    //         id: cellValues.row.id,
    //         companyId: cellValues.row.companyId,
    //         created: cellValues.row.created,
    //         deleted: cellValues.row.deleted,
    //         message: cellValues.row.message,
    //         requestStatus: cellValues.row.requestStatus,
    //         userId: cellValues.row.userId,
    //         userEmail: cellValues.row.userEmail,
    //         userFirstName: cellValues.row.userFirstName,
    //         userLastName: cellValues.row.userLastName,
    //         reply: cellValues.row.reply
    //     })
    //     // console.log("HERE", rowItem)
    //     handleOpen1();
    // }

    /// shows message the user has been sent in a modal
    function replyMessage(cellValues){
        console.log("CELLS", cellValues)
        setRowItem({
            id: cellValues.row.id,
            companyId: cellValues.row.companyId,
            created: cellValues.row.created,
            deleted: cellValues.row.deleted,
            message: cellValues.row.message,
            requestStatus: cellValues.row.requestStatus,
            userId: cellValues.row.userId,
            userEmail: cellValues.row.userEmail,
            userFirstName: cellValues.row.userFirstName,
            userLastName: cellValues.row.userLastName,
            reply: cellValues.row.reply,
            companyContact: cellValues.row.companyContact,
        })
        console.log("HERE REPLY", rowItem)
        handleOpen2();
    };

    const columns = [
        { field: 'companyContact', headerName: 'Company Contact', flex: 1, headerClassName: 'header', editable: false,},
        { field: 'companyEmail', headerName: 'Email', flex: 1, headerClassName: 'header', editable: false,},
        { field: 'requestStatus', headerName: 'Status', flex: 1, headerClassName: 'header', editable: false,
            renderCell: (cellValues)=>{
                if (cellValues.row.requestStatus === 0){
                    return(
                        <Chip icon={<Loader/>} variant="contained" color="secondary" label="Pending"/>
                    )
                } else if(cellValues.row.requestStatus === 1){
                    return(
                        <Chip icon={<CheckCircle/>} variant="contained" color="success" label="Approved with Credit"/>
                    )
                } else if(cellValues.row.requestStatus === 2){
                    return(
                        <Chip icon={<AlertCircle/>} variant="contained" color="warning" label="Approved with out Credit"/>
                    )
                } else if(cellValues.row.requestStatus === 3){
                    return(
                        <Chip icon={<XCircle/>} variant="contained" color="error" label="Denied"/>
                    )
                }
            }
        },
        { field: 'messageTab', headerName: 'Your Message', flex: 1, headerClassName: 'header', sortable: false,
            renderCell: (cellValues)=> (
                /// displays a popout with the message sent by the user
                <div>
                    <Button variant="contained" color="primary" onClick={() => showMessage(cellValues)}>Message</Button>
                </div>
        )
        },
        { field: 'replyTab', headerName: 'Reply from Processor', flex: 1, headerClassName: 'header', sortable: false,
            renderCell: (cellValues)=> (
                /// displays a popout with a reply from the processor
                <div>
                    <Button variant="contained" color="secondary" onClick={() => replyMessage(cellValues)}>Reply</Button>
                </div>
        )
        },
    ];
    

    return(
        <Box sx={{ '& .header': { backgroundColor: '#e2e2e2' }, height: '80vh' }}>
            <DataGrid
                key={i}
                editMode="row"
                editRowsModel={editRowsModel}
                columns={columns}
                rows={messages}
            />
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <MessageDisplay item={rowItem} style={style}/>
            </Modal>
            <Modal
                open={open2}
                onClose={handleClose2}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ReplyDisplay item={rowItem} style={style} handleClose1={handleClose1} handleClose2={handleClose2}/>
            </Modal>
        </Box>
    )
}

export default MessageDataGrid;