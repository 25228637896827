import { combineReducers } from "redux";

const allLicenses = (state = [], action) => {
    switch (action.type) {
        case "SET_ALL_LICENSES":
            state = action.payload;
            return state;
        default:
            return state;
    }
}

export default combineReducers({
    allLicenses,
});