import React from 'react';
import {
    Button,
    FormControl,
    OutlinedInput,
    InputAdornment,
    InputLabel,
    Select,
    TextField,
    MenuItem
} from '@mui/material';
import { Form, FormGroup, Label, Input, FormText, Dropdown } from 'reactstrap';
import { Check, PlusSquare } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Toast from '../../components/Toast';

function LicensingCreation(){
    const userCompany = useSelector((state) => state.CompanyReducer.userCompanies[0]);
    const dispatch = useDispatch();
    const [value, setValue] = React.useState(null);
    const [serialNumber, setSerialNumber] = React.useState();
    const [notes, setNotes] = React.useState('');
    const allCompanies = useSelector((state) => state.CompanyReducer.allCompanies);
    const companyUsers = useSelector((state) => state.CompanyReducer.usersInCompanyMenu);
    const [selectedComp, setSelectedComp] = React.useState('00000000-0000-0000-0000-000000000000');
    const [selectedUser, setSelectedUser] = React.useState('00000000-0000-0000-0000-000000000000');

    let rulerData = {
        rulerSerialNumber: serialNumber,
        rulerNotes: notes,
        userId: selectedUser,
    };

    function setSelectedCompany(event){
        setSelectedComp(event)
        dispatch({ type: 'GET_COMPANY_USERS', payload: {event:event , type:2}});
        // dispatch({ type: 'EDIT_EMPLOYEE_ROLE', payload: {UserId: item.userId, RoleName: 'Downloader Admin', CompanyId: company.id}});
    };

    ///create ruler
    function submitRuler(){
        
        if (serialNumber == null || serialNumber.match(/[E][v][a][n][G][3][-]\d\d\d\d\d\d/gm) == null){
            Toast.fire({
                title: 'Check Serial Number',
                icon: 'error'
            })
        } else {
            setSerialNumber('');
            setNotes('');
            dispatch({ type: "POST_RULER", payload: rulerData });
            // console.log(rulerData)
        }
    };

    return(
        <div>
            <h1>Ruler Management</h1>
            <Form style={{width:"100%", margin: 'auto'}}>
                <Label>Enter all Rulers Sold by Your Company <u>{userCompany.name}</u> Into the Botmark Management System.</Label>
                <br/>
                <FormControl sx={{ m: 1 }} style={{padding:10}}>
                    <Label style={{padding:10}} htmlFor="outlined-adornment-amount"><b>Ruler Serial Number #</b></Label>
                    <Label style={{padding:10}} htmlFor="outlined-adornment-amount">Please enter the Serial Number exactly as it appears on the EvanG3 Electronic Detailer.</Label>
                    <Label style={{padding:10}} htmlFor="outlined-adornment-amount">Example: EvanG3-000000</Label>
                    <OutlinedInput
                        style={{width:'50%', alignSelf: 'center' }}
                        // type="number"
                        id="outlined-adornment-amount"
                        onChange={(event)=>setSerialNumber(event.target.value)}
                    />
                </FormControl>
                <br/>
                <Label style={{padding:10}} htmlFor="outlined-adornment-amount">Assigning To a Ruler will only show a Buyer that already has an Existing Account.</Label>
                <br/>
                <Label style={{padding:10}} htmlFor="outlined-adornment-amount">If the buyer of the ruler does not yet have an account, leave the selection Unassigned for now.</Label>
                <br/>
                <FormControl style={{width:'25%', margin:5}}>
                    <InputLabel id="demo-simple-select-label">Company</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedComp}
                        label="Company"
                        onChange={(event) => setSelectedCompany(event.target.value)}
                    >
                        {/* <MenuItem value={'00000000-0000-0000-0000-000000000000'}>None</MenuItem> */}
                        {allCompanies.map((item) =>(
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl style={{width:'25%', margin:5}}>
                    <InputLabel id="demo-simple-select-label">Users</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedUser}
                        label="Users"
                        onChange={(event) => setSelectedUser(event.target.value)}
                    >
                        {/* <MenuItem value={'00000000-0000-0000-0000-000000000000'}>None</MenuItem> */}
                        {companyUsers.map((item) =>(
                            <MenuItem value={item.id}>{item.firstName} {item.lastName}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <br/>
                <FormControl sx={{ m: 2 }} style={{width:'25%'}}>
                    <Label htmlFor="outlined-adornment-amount">Ruler Notes</Label>
                    <TextField multiline rows={8} value={notes} onChange={(event)=>setNotes(event.target.value)}></TextField>
                </FormControl>
                {/* <FormControl sx={{ m: 1 }}>
                        <Label htmlFor="outlined-adornment-amount">Ruler Purchase Type</Label>
                        <Select
                            native
                            // value={status}
                            // onChange={handleChange}
                        >
                            <option aria-label="None" value="" />
                            <option value={0}>$8,000</option>
                            <option value={1}>$20,000</option>
                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1 }}>
                        <Label htmlFor="outlined-adornment-amount">Customer?</Label>
                        <Select
                            native
                            // value={status}
                            // onChange={handleChange}
                        >
                            <option aria-label="None" value="" />
                            <option value={0}>Customer A?</option>
                            <option value={1}>Customer B?</option>
                        </Select>
                    </FormControl> */}
                <br/>
                <Button color="primary" variant="contained" onClick={()=>submitRuler()}>
                    Generate
                </Button>
            </Form>
        </div>
    )
};

export default LicensingCreation;