import React from "react";
import {
    Card,
    Button,
    Container,
    TextField,
    Typography,
    Box,
    IconButton,
    Autocomplete
} from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { XSquare } from 'react-feather';
import { useDispatch, useSelector } from "react-redux";

// TODO: Add in Company of the user creating account.
// TODO: Add in User Role
const CreateUser = React.forwardRef((props, ref) => {
    const roles = useSelector((state) => state.CompanyReducer.allRoles);
    const company = useSelector((state) => state.CompanyReducer.userCompanies[0])
    const [existing, setExisting] = React.useState(false);
    const dispatch = useDispatch();
    const { close } = props;

    const newUser = () => {
        return (
            <Formik
                initialValues={{
                    UserName: '',
                    Email: '',
                    FirstName: '',
                    LastName: '',
                    Role: '',
                    Password: '',
                    Confirm: '',
                }}
                validationSchema={
                    Yup.object().shape({
                        UserName: Yup.string().max(255).required('Username is required'),
                        Email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                        FirstName: Yup.string().max(255).required('First name is required'),
                        LastName: Yup.string().max(255).required('Last name is required'),
                        Password: Yup.string().max(255).required('Password is required'),
                        Confirm: Yup.string().required('Confirm Password').oneOf([Yup.ref('Password'), null], 'Passwords must match')
                    })
                }
                onSubmit={(values) => {
                    const usr = {
                        UserName: values.UserName,
                        Email: values.Email,
                        FirstName: values.FirstName,
                        LastName: values.LastName,
                        Password: values.Password
                    }
                    const role = {
                        roleId: values.Role,
                        email: values.Email,
                        companyId: company.id
                    }
                    dispatch({
                        type: "NEW_USER_TO_COMPANY",
                        payload: {
                            usr: usr,
                            role: role
                        }
                    })
                    return close()
                }}
            >
                {({
                    errors,
                    handleBlur,
                    setFieldValue,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values
                }) => (
                    <form onSubmit={handleSubmit}>
                        <TextField
                            error={Boolean(touched.FirstName && errors.FirstName)}
                            fullWidth
                            helperText={touched.FirstName && errors.FirstName}
                            label="First Name"
                            margin="normal"
                            name="FirstName"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.FirstName}
                            variant="outlined"
                        />
                        <TextField
                            error={Boolean(touched.LastName && errors.LastName)}
                            fullWidth
                            helperText={touched.LastName && errors.LastName}
                            label="Last Name"
                            margin="normal"
                            name="LastName"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.LastName}
                            variant="outlined"
                        />
                        <TextField
                            error={Boolean(touched.UserName && errors.UserName)}
                            fullWidth
                            helperText={touched.UserName && errors.UserName}
                            label="Username"
                            margin="normal"
                            name="UserName"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.UserName}
                            variant="outlined"
                        />
                        <TextField
                            error={Boolean(touched.Email && errors.Email)}
                            fullWidth
                            helperText={touched.Email && errors.Email}
                            label="Email"
                            margin="normal"
                            name="Email"
                            type="email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.Email}
                            variant="outlined"
                        />
                        <Autocomplete
                            fullWidth
                            autoHighlight
                            options={roles}
                            name="Role"
                            onChange={(e, value) => setFieldValue("Role", value?.id || "")}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    error={Boolean(touched.Role && errors.Role)}
                                    helperText={touched.Roles && errors.Roles}
                                    label="Role"
                                    margin="normal"
                                    onBlur={handleBlur}
                                    value={values.Role}
                                />
                            )}
                        />
                        <TextField
                            error={Boolean(touched.Password && errors.Password)}
                            fullWidth
                            helperText={touched.Password && errors.password}
                            label="Password"
                            margin="normal"
                            name="Password"
                            type="password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.Password}
                            variant="outlined"
                        />
                        <TextField
                            error={Boolean(touched.Confirm && errors.Confirm)}
                            fullWidth
                            helperText={touched.Confirm && errors.Confirm}
                            label="Confirm password"
                            margin="normal"
                            name="Confirm"
                            type="password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.Confirm}
                            variant="outlined"
                        />
                        <Box my={2}>
                            <Button
                                color="primary"
                                disabled={isSubmitting}
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                            >
                                Create
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        )
    }

    const inviteUser = () => {
        return (
            <Formik
                initialValues={{
                    Email: '',
                    Role: ''
                }}
                validationSchema={
                    Yup.object().shape({
                        Email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                        Role: Yup.string().required('Role is required')                    
                    })
                }
                onSubmit={(values) => {
                    const msg = {
                        email: values.Email,
                        companyId: company.id,
                        roleId: values.Role
                    }
                    dispatch({
                        type: 'ADD_USER_TO_COMPANY',
                        payload: msg
                    })
                    return close()
                }}
            >
                {({
                    errors,
                    handleBlur,
                    setFieldValue,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values
                }) => (
                    <form onSubmit={handleSubmit}>
                        <TextField
                            error={Boolean(touched.Email && errors.Email)}
                            fullWidth
                            helperText={touched.Email && errors.Email}
                            label="Email"
                            margin="normal"
                            name="Email"
                            type="email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.Email}
                            variant="outlined"
                        />
                        <Autocomplete
                            fullWidth
                            autoHighlight
                            options={roles}
                            name="Role"
                            onChange={(e, value) => setFieldValue("Role", value?.id || "")}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    error={Boolean(touched.Role && errors.Role)}
                                    helperText={touched.Role && errors.Role}
                                    label="Role"
                                    margin="normal"
                                    onBlur={handleBlur}
                                    value={values.Role}
                                />
                            )}
                        />
                        <Box my={2}>
                            <Button
                                color="primary"
                                disabled={isSubmitting}
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                            >
                                Invite
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        )
    }

    return (
        <Card>
            <Box p={3} sx={{ display: 'flex', justifyContent: 'space-between', minWidth: '50vw' }}>
                <Typography
                    variant="h4"
                >
                    {existing ? "Invite User" : "Create User"}
                </Typography>
                <IconButton color="primary" onClick={close}>
                    <XSquare/>
                </IconButton>
            </Box>
            <Container maxWidth="md">
                <Button
                    color="primary"
                    size="large"
                    fullWidth
                    variant="outlined"
                    onClick={() => setExisting(!existing)}
                >
                    {existing ? "Add New User" : "Add Existing User"}
                </Button>
                {existing ? inviteUser() : newUser()}
            </Container>
        </Card>
    )
});

export default CreateUser;