import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Drawer,
  Hidden,
  List,
  Avatar,
  Divider,
  Typography,
  Badge,
  Select,
  FormControl,
  InputLabel,
  MenuItem
} from '@mui/material';
import {
  Lock as LockIcon,
  UserPlus as UserPlusIcon,
  Home as HomeIcon,
  DollarSign as DollarIcon,
  Info as InfoIcon,
  User as UserIcon,
  Users as UsersIcon,
  LogOut as LogoutIcon,
  Upload as UploadIcon,
  Folder as FolderIcon,
  Film as FilmIcon,
  Search as SearchIcon,
  MessageCircle as MessageIcon,
  Inbox as InboxIcon,
  Clipboard as ClipboardIcon,
  Mail as MailIcon,
  FileText as FileTextIcon,
  FilePlus as FilePlusIcon,
  Layers as LayersIcon,
  FileMinus as FileMinusIcon,
  Printer as PrinterIcon,
  BookOpen as BookOpenIcon,
  Book as BookIcon,
  List as ListIcon,
  Paperclip as PaperclipIcon,
  Thermometer as ThermometerIcon
} from 'react-feather';
import NavItem from './NavItem';
import { useSelector, useDispatch } from 'react-redux';
import SplitToken from '../../mixins/SplitToken';
import "../Layout.css";
import { styled } from '@mui/material/styles';
import getToken from '../../mixins/GetToken';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: 10,
      top: 21,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
}));

///standard menu render options
const items = [
    {
        href: '/instructions',
        icon: FilmIcon,
        title: 'Tutorial'
    },
    {
        href: '/findProcessor',
        icon: SearchIcon,
        title: 'Find Processor'
    },
];

///menu render options for processor Admins
const admin = [
    {
        href: '/planInbox',
        icon: LayersIcon,
        title: 'Plan Inbox'
    },
    {
        href: '/upload',
        icon: UploadIcon,
        title: 'Upload Plan'
    },
    {
        href: '/',
        icon: FileTextIcon,
        title: 'Plans'
    },
    // {
    //     href: '/EstimatesFromMe',
    //     icon: BookIcon,
    //     title: 'Sent Estimates'
    // },
    {
        href: '/EstimatesToMe',
        icon: BookOpenIcon,
        title: 'Received Estimates'
    },
    // {
    //     href: '/customers',
    //     icon: InboxIcon,
    //     title: 'Customer Management'
    // },
    // {
    //     href: '/Contacts',
    //     icon: UsersIcon,
    //     title: 'Processor Contacts'
    // },
];
///menu render options for external processor Admins
const externalAdmin = [
    {
        href: '/planInbox',
        icon: LayersIcon,
        title: 'Plan Inbox'
    },
    {
        href: '/upload',
        icon: UploadIcon,
        title: 'Upload Plan'
    },
    {
        href: '/',
        icon: FileTextIcon,
        title: 'Plans'
    },
    {
        href: '/EstimatesFromMe',
        icon: BookIcon,
        title: 'Sent Estimates'
    },
    {
        href: '/EstimatesToMe',
        icon: BookOpenIcon,
        title: 'Received Estimates'
    },
    {
        href: '/customers',
        icon: InboxIcon,
        title: 'Customer Management'
    },
    {
        href: '/Contacts',
        icon: UsersIcon,
        title: 'Processor Contacts'
    },
];
/// menu options for downloader Admins
const downloaderAdmin = [
    {
        href: '/',
        icon: LayersIcon,
        title: 'Plan Inbox'
    },
    {
        href: '/EstimatesToMe',
        icon: BookOpenIcon,
        title: 'Received Estimates'
    },
    {
        href: '/Contacts',
        icon: UsersIcon,
        title: 'Processor Contacts'
    },
];

/// menu options for downloader Admins
const downloaderOnly = [
    {
        href: '/',
        icon: LayersIcon,
        title: 'Plan Inbox'
    }
];

/// menu options for downloader Admins
const allBilling = [
    {
        href: '/',
        icon: LayersIcon,
        title: 'Sent Estimates'
    },
    {
        href: '/EstimatesToMe',
        icon: LayersIcon,
        title: 'Received Estimates'
    }
];

/// menu options for downloader Admins
const recievedBilling = [
    {
        href: '/',
        icon: LayersIcon,
        title: 'Received Estimates'
    }
];

/// menu options for a planner user
const planner = [
    {
        href: '/',
        icon: FileTextIcon,
        title: 'Plans'
    },
    {
        href: '/upload',
        icon: UploadIcon,
        title: 'Upload Plan'
    }
];

/// login/out menu options
const loginItems = [
    {
        href: '/login',
        icon: LockIcon,
        title: 'Login'
    },
    {
        href: '/register',
        icon: UserPlusIcon,
        title: 'Register'
    }
];

///menu options for Super Admins (develpers/BotwebOwner)
const superAdmin = [
    {
        href: '/companiesViewer',
        icon: ListIcon,
        title: 'Companies'
    },
    {
        href: '/RulerCreator',
        icon: ThermometerIcon,
        title: 'Rulers'
    },
    {
        href: '/licensing',
        icon: ClipboardIcon,
        title: 'Licensing'
    },
    {
        href: '/PDFupload',
        icon: PaperclipIcon,
        title: 'PDF/Tutorial Upload'
    },
];

const customList = [];

const MyDrawer = styled(Drawer)({
    width: 256,
    top: 64, 
    marginTop: '64px',
    },` & > div {
    width: 256px;
    border: none;
    margin-top: 64px;
    height: 94%
    }`
);

const NavBar = ({ onMobileClose, openMobile }) => {
    const dispatch = useDispatch();
    const allMessages = useSelector((state) => state.CompanyReducer.allCompanyMessages);
    const message0 = [];
    allMessages?.forEach(messagePusher);
    function messagePusher(allMessages) {
        if (allMessages.requestStatus === 0){
        message0.push({
            id: allMessages.id,
            companyId: allMessages.companyId,
            created: allMessages.created,
            deleted: allMessages.deleted,
            message: allMessages.message,
            requestStatus: allMessages.requestStatus,
            userId: allMessages.userId,
            userEmail: allMessages.userEmail,
            userFirstName: allMessages.userFirstName,
            userLastName: allMessages.userLastName,
            userCompany: allMessages.companyContact,
            companyStatus: allMessages.companyStatus
    })}};

    const user = useSelector((state) => state.UserReducer.isAuthenticated);
    const processorVersion = useSelector((state) => state.FileReducer.processorVersion.data);
    const currentCompany = useSelector((state) => state.CompanyReducer.userCompanies[0]);
    const userCompany = useSelector((state) => state.CompanyReducer.userCompanies);
    const allCompany = useSelector((state) => state.CompanyReducer.allCompanies);
    const [listItems, setListItems] = React.useState(items);
    const token = getToken();
    const userInfo = SplitToken();
    const location = useLocation();
    const [customListItems, setCustomListItems] = React.useState(customList);

    const startDateInitialState = dayjs().subtract(1, "years").startOf('day').format();
    const endDateInitialState = dayjs().endOf('day').format();
    
    const customerSelect = useSelector((state) => state.UserReducer.customerSelection);

    function setSelectedCustomer(event){
        dispatch({ type: 'CUSTOMER_SELECTION', payload: event })
        dispatch({ type: 'GET_COMPANY_FILES', payload: {companyId: currentCompany?.id, page: 0, pageSize: 25, customerId: event }})
        dispatch({ type: 'GET_COMPANY_INVOICES', payload: {companyId: currentCompany?.id, page: 0, pageSize: 25, customerId: event, startDate: startDateInitialState, endDate: endDateInitialState }})
    };

    const pendingCompany = [];
    allCompany?.forEach(companyPusher);
    function companyPusher(allCompany) {
        if (allCompany.companyStatus === 0){
            pendingCompany.push({
                companyStatus: allCompany.companyStatus,
            })
        }
    };

    function renderMenu(){
        setCustomListItems([]);
        if ( (user.theUserRoles[0] === "AD" && (customListItems.includes(admin) === false) && userCompany[0]?.companyStatus == 1 && userCompany[0]?.processExternal == false ||
              user.theUserRoles[0] === "AD" && (customListItems.includes(admin) === false) && userCompany[0]?.companyStatus == 2 && userCompany[0]?.processExternal == false)
         ) {
            console.log("Applying Admin");
            setListItems([]);
            setCustomListItems([]);
            if (customListItems[customListItems.length - 1]?.title !== "Users") {
                setListItems([...listItems]);
                setCustomListItems([...customListItems, ...admin]);
            }
        }
        if ( (user.theUserRoles[0] === "AD" && (customListItems.includes(externalAdmin) === false) && userCompany[0]?.companyStatus == 1 && userCompany[0]?.processExternal == true ||
              user.theUserRoles[0] === "AD" && (customListItems.includes(externalAdmin) === false) && userCompany[0]?.companyStatus == 2 && userCompany[0]?.processExternal == true)
         ) {
            console.log("Applying externalAdmin");
            setListItems([]);
            setCustomListItems([]);
            if (customListItems[customListItems.length - 1]?.title !== "Users") {
                setListItems([...listItems]);
                setCustomListItems([...customListItems, ...externalAdmin]);
            }
        }
        else if ( user.theUserRoles[0] == "DAD" && userCompany[0]?.companyStatus == 1 || 
        user.theUserRoles[0] == "DAD" && userCompany[0]?.companyStatus == 2 ){
            setListItems([]);
            setCustomListItems([]);
            console.log("Applying Downloader Admin");
            if (customListItems[customListItems.length - 1]?.title !== "Users") {
                setListItems([...listItems]);
                setCustomListItems([...customListItems, ...downloaderAdmin]);
            }
        }
        else if ( user.theUserRoles[0] == "BI" && userCompany[0]?.companyStatus == 1 || 
        user.theUserRoles[0] == "BI" && userCompany[0]?.companyStatus == 2 ){
            setListItems([]);
            setCustomListItems([]);
            console.log("Applying Billing");
            if (customListItems[customListItems.length - 1]?.title !== "Users") {
                setListItems([...listItems]);
                setCustomListItems([...customListItems, ...allBilling]);
            }
        }
        else if ( user.theUserRoles[0] == "RBI" && userCompany[0]?.companyStatus == 1 || 
        user.theUserRoles[0] == "RBI" && userCompany[0]?.companyStatus == 2 ){
            setListItems([]);
            setCustomListItems([]);
            console.log("Applying Billing");
            if (customListItems[customListItems.length - 1]?.title !== "Users") {
                setListItems([...listItems]);
                setCustomListItems([...customListItems, ...recievedBilling]);
            }
        }
        else if ( user.theUserRoles[0] == "PL" && userCompany[0]?.companyStatus == 1 ||
        user.theUserRoles[0] == "PL" && userCompany[0]?.companyStatus == 2 ){
            console.log("Applying Planner");
            setListItems([]);
            setCustomListItems([]);
            if (customListItems[customListItems.length - 1]?.title !== "Users") {
                setListItems([...listItems]);
                setCustomListItems([...customListItems, ...planner]);
            }
        }
        else if ( user.theUserRoles[0] == "DO") {
            setListItems([]);
            // setListItems(items);
            setCustomListItems([]);
            if (customListItems[customListItems.length - 1]?.title !== "Users") {
                setListItems([...listItems]);
                setCustomListItems([...downloaderOnly]);
            }
        }
        if (openMobile && onMobileClose) {
            onMobileClose();
        };
    };

    useEffect(()=>{
        // setListItems([]);
        // setCustomListItems([]);
        // dispatch({ type: "USER_DATA", payload: user.id });
        dispatch({type: "FETCH_PROCESSOR_VERSION"});
        renderMenu();
        // if (userInfo.isAdmin == true){
        //     console.error("MADE IT")
        dispatch({type: "GET_ALL_COMPANIES"});
        // }
    },[user , userCompany],[processorVersion]);

    useEffect(()=>{

    },[currentCompany]);

    console.log(currentCompany);

    const content = (
        <Box
            display="flex"
            flexDirection="column"
            sx={{ border: '1px solid #e0e0e0' }}
            >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                    p: 1
                }}
            >
                <Avatar
                    component={RouterLink}
                    to="/account"
                    sx={{
                        cursor: 'pointer',
                        width: 50,
                        height: 50,
                        m: 1,
                    }}
                    className='AvatarIcon'
                />
                <div>
                <Typography variant="h6" style={{marginLeft: 5}}>
                    Welcome:
                </Typography>
                <Typography variant='subtitle1'>
                    {user.userName}
                </Typography>
                </div>
            </Box>
            <div style={{marginLeft:10}}>
                {/* <List>
                {accountItems.map((item) => (
                    <NavItem
                        href={item.href}
                        key={item.title}
                        title={item.title}
                        icon={item.icon}
                    />
                ))}
                </List> */}
                <span>v. {processorVersion}</span>
            </div>
            {/* <Divider/> */}
            <div>
                {
                 (user.theUserRoles[0] === "AD" && currentCompany?.processExternal == true) ||
                 (user.theUserRoles[0] === "PL" && currentCompany?.processExternal == true) || 
                 (user.theUserRoles[0] === "BI" && currentCompany?.processExternal == true) ?
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Customer</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={customerSelect}
                            label="Customer"
                            onChange={(event) => setSelectedCustomer(event.target.value)}
                        >
                            <MenuItem value={'00000000-0000-0000-0000-000000000000'}>All</MenuItem>
                            {allMessages.map((item) =>(
                                <MenuItem value={item.userId}>
                                    {/* // <div> */}
                                        {item.requestStatus == 0 &&
                                            // <MenuItem style={{color:'purple'}} value={item.userId}>{item.userFirstName} {item.userLastName}: {item.companyContact}</MenuItem>
                                            <span style={{color:'purple'}}>{item.userFirstName} {item.userLastName}: {item.companyContact}</span>
                                        }
                                        {item.requestStatus == 1 &&
                                            // <MenuItem value={item.userId}>{item.userFirstName} {item.userLastName}: {item.companyContact}</MenuItem>
                                            <span style={{color:'green'}}>{item.userFirstName} {item.userLastName}: {item.companyContact}</span>
                                        }
                                        {item.requestStatus == 2 &&
                                            // <MenuItem style={{color:'darkOrange'}} value={item.userId}>{item.userFirstName} {item.userLastName}: {item.companyContact}</MenuItem>
                                            <span style={{color:'darkOrange'}}>{item.userFirstName} {item.userLastName}: {item.companyContact}</span>
                                        }
                                        {item.requestStatus == 3 &&
                                            // <MenuItem style={{color:'red'}} value={item.userId}>{item.userFirstName} {item.userLastName}: {item.companyContact}</MenuItem>
                                            <span style={{color:'red'}}>{item.userFirstName} {item.userLastName}: {item.companyContact}</span>
                                        }
                                    {/* </div> */}
                                </MenuItem>

                            ))}
                        </Select>
                    </FormControl>
                    :
                    <></>
                }
                {user.theUserRoles[0] === "PL" &&
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Customer</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={customerSelect}
                            label="Customer"
                            onChange={(event) => setSelectedCustomer(event.target.value)}
                        >
                            <MenuItem value={'00000000-0000-0000-0000-000000000000'}>All</MenuItem>
                            {allMessages.map((item) =>(
                                <div>
                                    {item.requestStatus == 0 &&
                                        <MenuItem style={{color:'purple'}} value={item.userId}>{item.userFirstName} {item.userLastName}: {item.companyContact}</MenuItem>
                                    }
                                    {item.requestStatus == 1 &&
                                        <MenuItem style={{color:'green'}} value={item.userId}>{item.userFirstName} {item.userLastName}: {item.companyContact}</MenuItem>
                                    }
                                    {item.requestStatus == 2 &&
                                        <MenuItem style={{color:'darkOrange'}} value={item.userId}>{item.userFirstName} {item.userLastName}: {item.companyContact}</MenuItem>
                                    }
                                    {item.requestStatus == 3 &&
                                        <MenuItem style={{color:'red'}} value={item.userId}>{item.userFirstName} {item.userLastName}: {item.companyContact}</MenuItem>
                                    }
                                </div>
                            ))}
                        </Select>
                    </FormControl>
                }
            </div>
            <Divider/>
            <Box p={1}>
                <List className="BadgeIcon">
                {customListItems.map((item, i) => (
                    <div>
                        { item.title == "Customers" ?
                            <StyledBadge badgeContent={message0.length} color="primary" className="BadgeIcon">
                                <NavItem
                                href={item.href}
                                key={item.title}
                                title={item.title}
                                icon={item.icon}
                                />
                            </StyledBadge>
                        :
                            <NavItem
                                href={item.href}
                                key={item.title}
                                title={item.title}
                                icon={item.icon}
                            />
                        }
                    </div>
                ))}
                </List>
            <Divider/>
                <List>
                    {listItems.map((item, i) => (
                        <NavItem
                            href={item.href}
                            key={item.title}
                            title={item.title}
                            icon={item.icon}
                        />
                    ))}
                </List>
            <Divider/>
            { userInfo?.isAdmin == "True" &&
                <List>
                    {superAdmin.map((item) => (
                               <div>
                               { item.title == "Companies" ?
                                   <StyledBadge badgeContent={pendingCompany.length} color="primary" className="BadgeIcon">
                                       <NavItem
                                       href={item.href}
                                       key={item.title}
                                       title={item.title}
                                       icon={item.icon}
                                       />
                                   </StyledBadge>
                               :
                                   <NavItem
                                       href={item.href}
                                       key={item.title}
                                       title={item.title}
                                       icon={item.icon}
                                   />
                               }
                           </div>
                    ))}
                </List>
            }
            </Box>
            <Divider/>
            {/* <Box p={2}>
                <List>
                {accountItems.map((item) => (
                    <NavItem
                        href={item.href}
                        key={item.title}
                        title={item.title}
                        icon={item.icon}
                    />
                ))}
                </List>
            <span>v. {processorVersion}</span>
            </Box> */}
            <Box flexGrow={1} />
        </Box>
    );

    const loginContent = (
        <Box
            display="flex"
            flexDirection="column"
            sx={{ border: '1px solid #e0e0e0' }}
        >
            <Box p={2}>
                <List>
                    {loginItems.map((item) => (
                    <NavItem
                        href={item.href}
                        key={item.title}
                        title={item.title}
                        icon={item.icon}
                    />
                    ))}
                </List>
            </Box>
            <Box flexGrow={1} />
        </Box>
    )

    return <>
        <Hidden lgUp>
            <Drawer
                anchor="left"
                sx={{ width: 256}}
                onClose={onMobileClose}
                open={openMobile}
                variant="temporary"
                classes={{
                    paper: {
                        width: '256px',
                    }
                }}
            >
                {user ? content : loginContent}
            </Drawer>
        </Hidden>
        <Hidden lgDown>
            <MyDrawer
                anchor="left"
                sx={{ border: 'none'}}
                open
                variant="persistent"
            >
                {user ? content : loginContent}
            </MyDrawer>
        </Hidden>
    </>;
};

NavBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
};

NavBar.defaultProps = {
    onMobileClose: () => {},
    openMobile: false
};

export default NavBar;