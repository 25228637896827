import React from 'react';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Page from "../../components/Page";
import { 
    Input,
    Progress,
    Form,
} from 'reactstrap';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import SplitToken from '../../mixins/SplitToken';
import "./ProcessorSearch.css";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const ProcessorSearch = () => {
    /// Displays a list of all publicly avalible processors and allow for a message to be sent between customer and company
    let user = SplitToken();
    const prosCompanies = useSelector((state) => state.CompanyReducer.allProsCompany);
    const [searchTerm, setSearchTerm] = useState("");
    const dispatch = useDispatch();
    const [message, setMessage] = useState('');
    const [modalitem, setModalItem] = useState();
    const [theModal, setTheModal] = useState(false);
    const ModalToggle = () => {setTheModal(!theModal)};

    function openModal(pros){
        setModalItem({
            id: pros.id,
            city: pros.city,
            country: pros.country,
            name: pros.name,
            processExternal: pros.processExternal,
            state: pros.state,
            street: pros.street,
            zip: pros.zip
        })
        ModalToggle();
    }

    
    function sendResquest(modalitem){
        let customerRequest = {
            UserId: user.id,
            CompanyId: modalitem.id,
            Message: message
        };
        dispatch({ type: 'REQUEST_PROCESSOR', payload: customerRequest, ModalToggle});
    }

    ///Search/Filter Companies Function
    let filterPros = [];
    let filterPros10 = [];
    let prosLength = prosCompanies.length;
    let filterProsLength = 0;

    if (searchTerm !== "") {
        filterPros = prosCompanies.filter(prosC => 
            prosC?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
            prosC?.zip?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
            prosC?.state?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
            prosC?.city?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
            prosC?.street?.toLowerCase()?.includes(searchTerm?.toLowerCase()))
        filterPros10 = filterPros.slice(0, 10)
        filterProsLength = filterPros.length;
    } else {
        filterPros = prosCompanies;
        filterPros10 = prosCompanies;
    }

    useEffect(() => {
        dispatch({ type: 'GET_ALL_PROCESSOR_COMPANY' })
    }, [])

    return (
        <Page>
            <div>
                <h2>Find Processor</h2>

                <div className="blogSideContent">
                    <h3 className="searchHeader">Processor Search</h3>
                    <p className="searchInfo">Search for a Plan Processor with names or addresses.</p>
                    <div className="searchArea">
                        <Input
                            className="blogSearch"
                            type="search"
                            placeholder="To search begin typing"
                            onChange={(event) => setSearchTerm(event.target.value)}
                            value={searchTerm}
                        ></Input>
                        <p className="blogcounter">Matches {filterProsLength} of {prosLength} Companies</p>
                        <Progress
                            max={prosLength}
                            value={filterProsLength}
                            className="blogbar"
                        ></Progress>
                    </div>
                    <ul className="processorList" style={{padding: 'inherit'}}>
                        {
                            filterPros?.map((pros, i) => {
                                return (
                                    <div key={i} i={i} className="processorIndex">
                                        <h3>{pros?.name}</h3>
                                        <p>{pros?.street}</p>
                                        <p>{pros?.city} {pros?.state}, {pros?.zip}</p>
                                        <Button onClick={() => openModal(pros)}>Request</Button>
                                    </div>
                                )
                            })
                        }
                    </ul>
                    <Modal 
                        open={theModal}
                        onClose={ModalToggle}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        modalitem={modalitem}
                    >
                        <Box sx={style} className="messageModal">
                            <Typography id="modal-modal-title" variant="h5" component="h2">
                                Message to {modalitem?.name}
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                Make a processing request to {modalitem?.name}? Include a short message if needed. Otherwise this will connect you with a processor notifiying them of interest.
                            </Typography>
                            <Form className='messageForm'>
                                <TextField
                                    sx={{ mt: 2 }}
                                    id="outlined-multiline-static"
                                    label="Message"
                                    multiline
                                    rows={4}
                                    value={message}
                                    onChange={(event)=> setMessage(event.target.value)}
                                    />   
                                <Button className="messageBtn" onClick={() => sendResquest(modalitem)}>Send Request</Button>
                            </Form>
                        </Box>
                    </Modal>
                </div>
            </div>
        </Page>
    )
};

export default ProcessorSearch;