import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import Toast from '../../components/Toast';
import getToken from '../../mixins/GetToken';
import { useNavigate } from 'react-router-dom';
import SplitToken from '../../mixins/SplitToken';

function* postRuler(action) {
    const user = SplitToken();
    const token = getToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try {
        let ruler = yield axios.post(`/api/Ruler/PostRuler`, action.payload, config);
        if (ruler.status === 200) {
            Toast.fire({
                title: `${action.payload.rulerSerialNumber} Ruler Generated`,
                icon: 'success',
            })
            yield put({
                type: "GET_RULERS",
            });
            console.log("ACTION",action.payload)
        }
        else {
            return Toast.fire({
                title: 'Creation failed',
                icon: 'error',
            })
        }
    } catch (error) {
        console.log(error)
    }
}

function* fetchAllRulers(action){
    const token = getToken();
    const userToken = SplitToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try{
        let allRulers = yield axios.get(`/api/Ruler/FetchRulers`, config);
        yield put({
            type: "SET_ALL_RULERS",
            payload: allRulers.data
        });

    } catch(error){
        console.log(error)
    }
}

function* updateRuler(action){
    const token = getToken();
    const userToken = SplitToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try{
        let ruler = yield axios.put(`/api/Ruler/UpdateRulers`, action.payload, config);
        if (ruler.status === 200) {
            Toast.fire({
                title: `${action.payload.RulerSerialNumber} Ruler Updated`,
                icon: 'success',
            })
            yield put({
                type: "GET_RULERS",
            });
            console.log("ACTION",action.payload)
        }
        else {
            return Toast.fire({
                title: 'Update failed',
                icon: 'error',
            })
        }

    } catch(error){
        console.log(error)
    }
}

function* RulerSaga() {
    yield takeLatest("POST_RULER", postRuler);
    yield takeLatest("GET_RULERS", fetchAllRulers);
    yield takeLatest("UPDATE_RULER", updateRuler);
}

export default RulerSaga;