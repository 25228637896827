import React from 'react';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../components/Page';
import {
    Box,
    Typography,
    Button,
    Hidden,
    Modal,
    Chip,    
} from '@mui/material';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarExport
} from '@mui/x-data-grid';
import {
    Wifi,
    WifiOff,
} from 'react-feather';
import CompanyStatusChange from './CompanyStatusChange';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const CompanyViewer = () => {
    /// Company data grid for super admins shows all registered companies
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [check, setCheck] = React.useState(false);
    const role = useSelector((state) => state.CompanyReducer.userRole);
    const users = useSelector((state) => state.CompanyReducer.usersInCompany);
    const allCompanies = useSelector((state) => state.CompanyReducer.allCompanies);
    const [rowItem, setRowItem] = useState();
    const [editRowsModel, setEditRowsModel] = React.useState({});
    const [open1, setOpen1] = React.useState(false);
    const handleOpen1 = () => setOpen1(true);
    const handleClose1 = () => setOpen1(false);
    const [open2, setOpen2] = React.useState(false);
    const handleOpen2 = () => setOpen2(true);
    const handleClose2 = () => setOpen2(false);

    function editStatus(cellValues) { // hadles the double click to bring up modal to edit crop amount
        setRowItem({
            id: cellValues.row.id,
            name: cellValues.row.name,
            street: cellValues.row.street,
            city: cellValues.row.city,
            state: cellValues.row.state,
            zip: cellValues.row.zip,
            country: cellValues.row.country,
            processExternal: cellValues.row.processExternal,
            email: cellValues.row.email,
            phoneNumber: cellValues.row.phoneNumber,
            companyStatus: cellValues.row.companyStatus
        })
        handleOpen1();
    };

    const columns = [
        { field: 'name', headerName: 'Name', flex: 1, headerClassName: 'header' },
        { field: 'email', headerName: 'Email', flex: 1, headerClassName: 'header' },
        { field: 'phoneNumber', headerName: 'Number', flex: .75, headerClassName: 'header' },
        { field: 'processExternal', headerName: 'External Processor', flex: .5, headerClassName: 'header',
            renderCell: (cellValues)=>{
                if (cellValues.row.processExternal === true){
                    return(
                        <Chip icon={<Wifi/>} variant="contained" color="primary" label="External"/>
                    )
                } else if(cellValues.row.processExternal === false){
                    return(
                        <Chip icon={<WifiOff/>} variant="contained" color="secondary" label="Internal"/>
                    )
                }
            } 
        },
        { field: 'companyStatus', headerName: 'Company Status', flex: .85, headerClassName: 'header', 
            renderCell: (cellValues) => {
                if (cellValues.row.companyStatus === 0){
                    return(
                        <Button className="statusButton" color="primary" onClick={() => editStatus(cellValues)}>Pending</Button>
                    )
                } else if(cellValues.row.companyStatus === 1){
                    return(
                        <Button className="statusButton" color="success" onClick={() => editStatus(cellValues)}>Approved with Credit</Button>
                    )
                } else if(cellValues.row.companyStatus === 2){
                    return(
                        <Button className="statusButton" color="warning" onClick={() => editStatus(cellValues)}>Approved with out Credit</Button>
                    )
                } else if(cellValues.row.companyStatus === 3){
                    return(
                        <Button className="statusButton" color="error" onClick={() => editStatus(cellValues)}>Denied</Button>
                    )
                }
            }
        },
    ];

    const handleOpen = () => setOpen(!open);
    const handleCheck = () => setCheck(!check);

    function CustomToolBar() {
        return (
            <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #e0e0e0', p: 1 }}>
                <Hidden smDown>
                    <Box width='40%' textAlign="left">
                        <GridToolbarFilterButton/>
                        <GridToolbarColumnsButton/>
                        <GridToolbarDensitySelector/>
                        <GridToolbarExport/>
                    </Box>
                </Hidden>
                <Hidden smUp>
                </Hidden>
            </GridToolbarContainer>
        )
    };

    useEffect(() => {
        dispatch({ type: 'GET_ALL_COMPANIES' })
    }, []);

    return (
        <Page title="Companies">
            <Box p={3} sx={{ '& .header': { backgroundColor: '#e2e2e2' } }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant='h3' textAlign='left' p={2}>
                        Companies
                    </Typography>
                </Box>
                <DataGrid
                    components={{
                        Toolbar: CustomToolBar
                    }}
                    columns={columns}
                    rows={allCompanies}
                    sx={{ height: '70vh' }}
                />
                <Modal
                    open={open1}
                    onClose={handleClose1}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                <CompanyStatusChange item={rowItem} style={style} handleClose1={handleClose1} handleClose2={handleClose2}/>
            </Modal>
            </Box>
        </Page>
    )
};

export default CompanyViewer;