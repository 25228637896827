import React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Link,
  Backdrop,
  CircularProgress
} from "@mui/material";
import Page from "../../../src/components/Page";
import { styled } from "@mui/system";
import theme from "../../theme";
import { useDispatch, useSelector } from "react-redux";

const MyPage = styled(Page)({
  backgroundColor: theme.palette.background.default,
  height: "100%",
  paddingBottom: theme.spacing(3),
  paddingTop: theme.spacing(3),
});

const LoginView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.UserReducer.isLoading);

  return (
    <MyPage title="Login">
        <Box
            display="flex"
            flexDirection="column"
            height="100%"
            justifyContent="center"
        >
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress size={100} color="warning" />
            </Backdrop>
            <Container maxWidth="sm" style={{border:'solid black 2px', borderRadius:'10px', boxShadow: '5px 5px 5px 3px rgba(168,168,168,0.45)'}}>
                <Formik
                    initialValues={{
                        email: "",
                        password: "",
                    }}
                    validationSchema={Yup.object().shape({
                        email: Yup.string().max(255).required("Username is required"),
                        password: Yup.string().max(255).required("Password is required"),
                    })}
                    onSubmit={(values) => {
                        dispatch({
                            type: "USER_LOGIN",
                            payload: { Username: values.email, Password: values.password },
                        });
                        navigate("/", { replace: true });
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                    }) => (
                    <form onSubmit={handleSubmit}>
                        <Box mb={3}>
                            <Typography color="textPrimary" variant="h2">
                                Sign in
                            </Typography>
                        </Box>
                        <Box mt={3} mb={1}></Box>
                        <TextField
                            error={Boolean(touched.email && errors.email)}
                            fullWidth
                            helperText={touched.email && errors.email}
                            label="Username"
                            margin="normal"
                            name="email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="text"
                            value={values.email}
                            variant="outlined"
                        />
                        <TextField
                            error={Boolean(touched.password && errors.password)}
                            fullWidth
                            helperText={touched.password && errors.password}
                            label="Password"
                            margin="normal"
                            name="password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="password"
                            value={values.password}
                            variant="outlined"
                        />
                        <Box my={2}>
                            <Button
                                color="primary"
                                disabled={isSubmitting}
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                            >
                                Sign in now
                            </Button>
                        </Box>
                        <Typography color="textSecondary" variant="body1">
                            Don&apos;t have an account?{" "}
                            <Link component={RouterLink} to="/register" variant="h6">
                                Sign up
                            </Link>
                        </Typography>
                        <Typography color="textSecondary" variant="body1">
                            <Link component={RouterLink} to="/requestpassword" variant="h6">
                                Forgot Login
                            </Link>
                        </Typography>
                    </form>
                    )}
                </Formik>
            </Container>
        </Box>
    </MyPage>
  );
};

export default LoginView;
