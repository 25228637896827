import React from "react";
import {
    Box, Typography
} from '@mui/material';
import PlanTable from "./PlanTable";
import Page from '../../components/Page'
import { useSelector } from "react-redux";

const PlanPage = () => {
    const company = useSelector((state) => state.CompanyReducer.userCompanies[0]);
    return (
        <Page title="Plans">
            <Box p={3}>
                <Box>
                    <Typography
                        variant="h3"
                        p={2}
                        textAlign="left"
                    >
                        {company ? company.name : ''}
                    </Typography>
                </Box>
                <PlanTable/>
            </Box>
        </Page>
    )
};

export default PlanPage;