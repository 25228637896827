import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import Page from '../../components/Page';
import {
    Grid,
    Typography,
    Box,
    Card,
    CardHeader
} from '@mui/material';
import Youtube from 'react-youtube';

const videos = [
    {
        title: "Robotic Layout",
        videoId: 'RvRxfZoowvI'
    },
    {
        title: "Robotic layout using Botmark system",
        videoId: 'P-YrpP0XY4M'
    },
    {
        title: 'Robotic layout using Botmark system (2)',
        videoId: 'Pqzcknda5IU'
    }
]

const Videos = () => {
    ///// Currently vids either require a youtube Id or a embed version of a youtube link to function /////
    const dispatch = useDispatch();
    // const vid1 = useSelector((state) => state.FileReducer.vid1);
    // const vid2 = useSelector((state) => state.FileReducer.vid2);
    const vids = useSelector((state) => state.FileReducer.vids);
    const opts = {
        height: '300px',
        width: '100%',
    }

    React.useEffect(() => {
        dispatch({ type: 'GET_VIDEO_URLS'});
    },[]);

    return (
        <Page title="Tutorial">
            <Box p={3}>
                <Typography variant='h3' textAlign="left" marginBottom="20px">
                    Tutorial Videos
                </Typography>
                <Grid container spacing={3}>
                    {videos.map((x, i) => (
                        <Grid key={i} item xs={12} sm={12} md={6} lg={4} >
                            <Card>
                                <CardHeader sx={{ border: '1px solid black' }} title={x.title}/>
                                <Youtube videoId={x.videoId} opts={opts} />
                            </Card>
                        </Grid>
                    ))}
                    {/* {vids.map((x,i) => (
                         <Grid key={i} item xs={12} sm={12} md={6} lg={4} >
                            <Card>
                                {/* <CardHeader sx={{ border: '1px solid black' }} title={x.title}/> */}
                                {/* <iframe src={x.vidUrl}></iframe>
                            </Card>
                        </Grid>
                    ))} */}
                </Grid>
            </Box>
        </Page>
    )
};

export default Videos;