import { combineReducers } from "redux";

const allRulers = (state = [], action) => {
    switch (action.type) {
        case "SET_ALL_RULERS":
            state = action.payload;
            return state;
        default:
            return state;
    }
}

export default combineReducers({
    allRulers,
});