import getToken from "./GetToken";

function SplitToken(){
    const token = getToken();
    if (!token){return};
 
    function parseJwt(token) {
        if (!token) { return; }
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    }

    const pulledToken = parseJwt(token);
    const userArray = Object.values(pulledToken);
    const userDataArray = userArray[3];
    const user = { 
        id: userArray[0],
        userName: userArray[1],
        EmailId: userArray[2],

        //userArray[3]
        isAdmin: userDataArray[0],
        downloaderOnly: userDataArray[1],
        hasReadPrivacyPolicy: userDataArray[2],
        hasConfirmedEmail: userDataArray[3],
        hasAcceptedDownloadPolicy: userDataArray[4],
        hasAcceptedUploadPolicy: userDataArray[5],
        theUserRoles: userDataArray[6],

        FirstName: userArray[4],
        LastName: userArray[5],
    }
    // console.log("token is", token);
    // console.log("pulled token", pulledToken);
    // console.log("ARRAY", userArray)
    // console.log("user is", user);
    // console.log('role is', user.theUserRoles)
    return user;
}

export default SplitToken;